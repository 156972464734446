/* eslint-disable @typescript-eslint/no-empty-function */
import Auth from "@aws-amplify/auth";
import { ActionContext } from "vuex";
import { AuthState, Tenant } from "./state";
import { AxiosRequestConfig } from "axios";
import {
  TENANT,
  ACCOUNTS_BASE_API,
  delTenantFromCookie,
  IS_NEW_ACCOUNT_MODEL_ENABLED,
  ACCOUNTS_LOGOUT,
  ERR_CODE_USER_DISABLED,
} from "@/config/constants";
import { AuthRequest } from "@/services/truora_api";
import { isCookiesAuthEnabled } from "@/helpers/cookies";

export const signIn = (): void => {};

export const changePassword = (): void => {};

export const authenticate = async function (
  context: ActionContext<AuthState, Record<string, unknown>>
): Promise<null> {
  if (isCookiesAuthEnabled()) {
    await context.dispatch("tenant");

    return null;
  }

  const user = await Auth.currentAuthenticatedUser();
  context.commit("setUser", user);

  return null;
};

export const logout = async function (
  context: ActionContext<AuthState, Record<string, unknown>>
): Promise<void> {  
    delTenantFromCookie();
    context.commit("setUser", {});  

    const params = new URLSearchParams({ error: ERR_CODE_USER_DISABLED });
    const logoutUrl = `${ACCOUNTS_LOGOUT}?${params.toString()}`;

    window.location.href = logoutUrl;
};

export const tenant = async function (
  context: ActionContext<AuthState, Record<string, unknown>>
): Promise<Tenant> {
  if (!!context.state.tenant) {
    return context.state.tenant;
  }

  const config: AxiosRequestConfig = {
    method: "post",
    url: "/v1/account/tenant",
    baseURL: ACCOUNTS_BASE_API,
  };

  const { data } = await AuthRequest.post("/v1/account/tenant", config);

  delete data.billing_information;

  context.commit("setTenant", data);
  context.commit("setUsername", data.username);

  if (IS_NEW_ACCOUNT_MODEL_ENABLED) {
    return data;
  }

  context.commit("setAccount", TENANT);

  return data;
};

export const forgotPassword = (): void => {};

export const forgotPasswordSubmit = (): void => {};

export const resetPassword = (): void => {};
