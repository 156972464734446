export interface ZapsignState {
  zapsignTemporalToken: string
  zapsignTemporalTokenStatus: string
  zapsignTemporalTokenError: object | undefined
  zapsignTemporalTokenLoading: boolean
}


export const defaultState : ZapsignState = {
  zapsignTemporalToken: "",
  zapsignTemporalTokenStatus: "",
  zapsignTemporalTokenError: undefined,
  zapsignTemporalTokenLoading: false
}

export default {
  ...defaultState
}
