
import Vue from "vue"

export default Vue.extend({
  props: {
    items: {
      required: true,
      type: Array as () => string[],
    },
    disabled: {
      type: Boolean,
    },
    width: {
      type: String,
    },
  },
  data() {
    return {
      selectedItem: '',
      dropdownClass: {
        width: ''
      },
    }
  },
  mounted () {
    this.dropdownClass = {
      width: '20rem'
    }
    this.selectedItem = this.items[0]
    if (this.width !== undefined) {
      this.dropdownClass.width = this.width
    }
  },
  watch: {
    selectedItem(value) {
      this.$emit('selected', value)
    }
  }
})
