export enum ValidationMetricsStatus{
    COMPLETED='completed',
    IN_PROGRESS='in_progress',
    PENDING_DATA='pending_data'
}

export interface ValidationMetrics {
    status: string;
    timestamp: number,
    result: string;
}
