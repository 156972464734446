import {Validation} from "@/models/validation";
import {ItemType} from "@/models/details";
import jp from 'jsonpath'
import {DOC_TYPE, GENDER, parseDate} from "@/models/details/details_data";

export default function getPENationalIDFields(validation: Validation): { type: ItemType, label: string, value: any }[] {
  return [
    {
      type: ItemType.ROW,
      label: 'Tipo de documento',
      value: DOC_TYPE[jp.value(validation, '$..details.document_details.document_type')]
    },
    {
      type: ItemType.ROW,
      label: 'Número de documento',
      value: jp.value(validation, '$..details.document_details.document_number')
    },
    {
      type: ItemType.ROW,
      label: 'Nombres',
      value: jp.value(validation, '$..details.document_details.name')
    },
    {
      type: ItemType.ROW,
      label: 'Apellidos',
      value: jp.value(validation, '$..details.document_details.last_name')
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de nacimiento',
      value: parseDate(jp.value(validation, '$..details.document_details.date_of_birth'), true)
    },
    {
      type: ItemType.ROW,
      label: 'Género',
      value: GENDER[jp.value(validation, '$..details.document_details.gender')]
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de expedición',
      value: parseDate(jp.value(validation, '$..details.document_details.issue_date'), true)
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de expiración',
      value: parseDate(jp.value(validation, '$..details.document_details.expiration_date'), true)
    },
    {
      type: ItemType.ROW,
      label: 'Domicilio',
      value: jp.value(validation, '$..details.document_details.residence_address')
    },
    {
      type: ItemType.ROW,
      label: 'Lugar de nacimiento',
      value: jp.value(validation, '$..details.document_details.birth_place')
    },
  ]
}
