import { render, staticRenderFns } from "./ValidationDetail.vue?vue&type=template&id=9698196e&scoped=true"
import script from "./ValidationDetail.vue?vue&type=script&lang=ts"
export * from "./ValidationDetail.vue?vue&type=script&lang=ts"
import style0 from "./ValidationDetail.vue?vue&type=style&index=0&id=9698196e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9698196e",
  null
  
)

export default component.exports