// Ready translated locale messages.
import Vue from "vue"
import VueI18n from "vue-i18n"
import messages from "@/i18n/translations"

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: "es",
  messages,
  fallbackLocale: {
    // This is how we been using the library, if a translation is not found
    // simply display the key.
    es: ["es"],
    en: ["en"],
    pt: ["pt"],
  },
  formatFallbackMessages: true,
  silentTranslationWarn: true,
})
