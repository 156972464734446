
import Vue from "vue"
import { mapGetters, mapMutations, mapActions, mapState } from "vuex"

import GenericTable from '@bit/truora.pneuma.generic-table'
import Btn from '@bit/truora.pneuma.btn'
import Snackbar from '@bit/truora.pneuma.snackbar'
import Datepicker from '@bit/truora.pneuma.datepicker'
import HistoryDownload from "@/components/Validations/HistoryDownload.vue";
import {
  Validation,
  ValidationStatus,
  ValidationTypes,
  ValidationPrefix,
  AccountIDPrefix
} from '@/models/validation'
import {
  VALIDATIONS_NAMES,
  VALIDATIONS_STATUS,
  VALIDATIONS_FAILURE_STATUS
} from '@/config/validation'

const tableHeaders = [
    {
      align: 'center',
      sortable: false,
      text: 'Tipo de validación',
      value: 'type'
    },
    {
      align: 'center',
      sortable: false,
      text: 'ID cuenta',
      value: 'account_id'
    },
    {
      align: 'center',
      sortable: false,
      text: 'ID validación',
      value: 'validation_id'
    },
    { align: 'center', sortable: false, text: 'Fecha', value: 'creation_date' },
    {
      align: 'center',
      sortable: true,
      text: 'Estado',
      value: 'validation_status'
    },
    {
      align: 'center',
      sortable: false,
      text: 'Estado del Fallo',
      value: 'failure_status'
    }
  ]

  const loadingConfig = { message: 'Cargando', state: true }

  const validatorItems = [
    {
      text: VALIDATIONS_NAMES[ValidationTypes.PhoneVerification],
      value: ValidationTypes.PhoneVerification
    },
    {
      text: VALIDATIONS_NAMES[ValidationTypes.EmailVerification],
      value: ValidationTypes.EmailVerification
    },
    {
      text: VALIDATIONS_NAMES[ValidationTypes.DocumentValidation],
      value: ValidationTypes.DocumentValidation
    },
    {
      text: VALIDATIONS_NAMES[ValidationTypes.FaceRecognition],
      value: ValidationTypes.FaceRecognition
    },
    {
      text: VALIDATIONS_NAMES[ValidationTypes.VoiceRecognition],
      value: ValidationTypes.VoiceRecognition
    },
    {
      text: VALIDATIONS_NAMES[ValidationTypes.IdentityQuestions],
      value: ValidationTypes.IdentityQuestions
    },
    {
      text: VALIDATIONS_NAMES[ValidationTypes.EnterpriseData],
      value: ValidationTypes.EnterpriseData
    },
    {
      text: VALIDATIONS_NAMES[ValidationTypes.FaceSearch],
      value: ValidationTypes.FaceSearch
    },
    {
      text: VALIDATIONS_NAMES[ValidationTypes.ElectronicSignature],
      value: ValidationTypes.ElectronicSignature
    }
  ]

  const statusItems = [
    {
      text: VALIDATIONS_STATUS[ValidationStatus.Success],
      value: ValidationStatus.Success
    },
    {
      text: VALIDATIONS_STATUS[ValidationStatus.Pending],
      value: ValidationStatus.Pending
    },
    {
      text: VALIDATIONS_STATUS[ValidationStatus.Failure],
      value: ValidationStatus.Failure
    }
  ]
export default Vue.extend({
  components: {
    GenericTable,
    Btn,
    Datepicker,
    Snackbar,
    HistoryDownload,
  },
  data() {
    return {
      tableHeaders,
      loadingConfig,
      downloadHistoryDialogVisible: false,
      validatorItems,
      statusItems,
      validationType : '',
      validationStatus : '',
      itemID : '',
      notification : {
        show: false,
        title: '',
        message: '',
        type: ''
      },
      startDate : '',
      endDate : ''
    }
  },
  methods: {
    ...mapActions("validations", ["fetchAllValidations", "fetchPreviousValidationsPage", "fetchNextValidationsPage", "fetchValidationByID"]),
    ...mapMutations("validations", ["setParams", "setItemID", "resetParams", "resetItemID"]),
    setStartDate (startDate: string): void {
      this.startDate = startDate
    },
    setEndDate (endDate: string): void {
      this.endDate = endDate
    },
    goToValidationDetail (validation: Validation): void {
      this.$gtag.event('history_item_clicked', {
        validation_type: validation.type,
        validation_status: validation.validation_status
      })

      this.$router.push(`/history/${validation.validation_id}`)
    },
    setValuesFromStore (): void {
      const params:Record<string, string> | undefined = this.getParams

      if (params !== undefined) {
        if (params.type !== undefined) {
          this.validationType = params.type
        }

        if (params.start_date !== undefined && params.end_date !== undefined) {
          this.startDate = params.start_date
          this.endDate = params.end_date
        }

        if (params.validation_status !== undefined) {
          this.validationStatus = params.validation_status
        }
      }

      this.itemID = this.getItemID;
    },
    downloadValidationsHistory(): void {
      this.downloadHistoryDialogVisible = true;
    },
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
    getSearchParams () {
      let params:Record<string, string> | undefined = this.getParams

      if (params == undefined) {
        params = {}
      }

      if (this.startDate !== '' && this.endDate !== '') {
        params.start_date = this.startDate
        params.end_date = this.endDate
      }

      if (this.validationType !== '') {
        params.type = this.validationType
      }

      if (this.validationStatus !== '') {
        params.validation_status = this.validationStatus
      }

      if (Object.keys(params).length === 0) {
        return undefined
      }

      this.setParams(params)

      // Do nothing if there's no param set
      return params
    },
    searchValidationsInPage (isNextPage: boolean): void {
      const params = this.getSearchParams()

      if (isNextPage) {
        this.$gtag.event('pagination_clicked', {
          pagination_direction: 'next_page'
        })

        this.fetchNextValidationsPage(params)
        return
      }

      this.$gtag.event('pagination_clicked', {
        pagination_direction: 'previous_page'
      })
      this.fetchPreviousValidationsPage(params)
    },
    searchValidationByAccountOrValidationID (): void {
      const itemID = this.itemID

      const searchByValidationID =
        itemID.length > 3 &&
        itemID.substring(0, 3).toUpperCase() === ValidationPrefix

      if (searchByValidationID) {
        this.fetchValidationByID(itemID)
        return
      }

      const searchByAccountID =
        itemID.length > 3 &&
        itemID.substring(0, 3).toUpperCase() === AccountIDPrefix

      if (searchByAccountID) {
        this.fetchAllValidations({ account_id: itemID })
        return
      }

      this.fetchAllValidations({ begins_with: itemID })
    },
    searchValidations (): void {
      if (this.itemID !== '') {
        this.setItemID(this.itemID)
        this.searchValidationByAccountOrValidationID()
        this.reportFilter('filter-by-id')
        return
      }

      const params = this.getSearchParams()
      this.fetchAllValidations(params)
      this.reportFilter('filter-by-params')
    },
    resetValidations (): void {
      this.validationType = ''
      this.validationStatus = ''
      this.itemID = ''
      this.startDate = ''
      this.endDate = ''
      this.resetParams(this.state)
      this.resetItemID(this.state)
    },
    reportFilter (filterType: 'filter-by-id' | 'filter-by-params'): void {
      const eventParameters = {
        filter_by_type: false,
        filter_by_status: false,
        filter_by_from_date: false,
        filter_by_until_date: false,
        filter_by_id: false,
        filter_by_account_id: false,
        filter_by_validation_id: false,
        compound_filter: ''
      }

      if (filterType === 'filter-by-id') {
        eventParameters.filter_by_id = true
        eventParameters.filter_by_validation_id = this.itemID
          .toUpperCase()
          .startsWith(ValidationPrefix)
        eventParameters.filter_by_account_id =
          !eventParameters.filter_by_validation_id
      }

      if (filterType === 'filter-by-params') {
        eventParameters.filter_by_type = this.validationType !== ''
        eventParameters.filter_by_status = this.validationStatus !== ''
        eventParameters.filter_by_from_date = this.startDate !== ''
        eventParameters.filter_by_until_date = this.endDate !== ''
      }

      const activeFilters = Object.entries(eventParameters).filter(
        ([, isFiltering]) => isFiltering
      )
      const compoundFilter = activeFilters
        .map(([filterName]) => filterName)
        .join(',')
        .replace(/filter_by_/g, '')

      eventParameters.compound_filter = compoundFilter

      this.$gtag.event('history_filtered', eventParameters)
    }
  },
  computed: {
    ...mapGetters("validations", ["getValidationsHistory", "getValidationsLoading", "getParams", "getItemID"]),
    ...mapState("validations",["state", "error"]),
    canResetForm () {
      return (
        this.validationType === '' &&
        this.validationStatus === '' &&
        this.itemID === '' &&
        this.startDate === '' &&
        this.endDate === ''
      )
    },
    validationsHistory () {
      return this.getValidationsHistory.map((validation) => ({
        type: VALIDATIONS_NAMES[validation.type],
        account_id: validation.account_id,
        validation_id: validation.validation_id,
        creation_date: new Date(validation.creation_date).toLocaleDateString(
          undefined,
          {
            year: 'numeric',
            month: 'short',
            day: 'numeric'
          }
        ), // Use default locale with undefined param
        validation_status: VALIDATIONS_STATUS[validation.validation_status],
        failure_status: validation.failure_status
          ? VALIDATIONS_FAILURE_STATUS[validation.failure_status]
          : ' '
      }))
    }
  },
  watch: {
    error(value) {
      if (value) {
        this.notification = {
          show: true,
          title: value.message,
          message: '',
          type: 'error'
        }
      }
    },
  },
  beforeMount (): void {
    this.setValuesFromStore()
    this.searchValidations()
  }
})
