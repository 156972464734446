
import Vue from "vue"
import { LayoutItem } from '../../models/details'
import NotFoundImage from '../../assets/images/image_not_found.png'

export default Vue.extend({
  props: {
    layoutItem: {
      required: true,
      type: Object as () => LayoutItem,
    },
  },
  computed: {
    showMediaCard() {
      const layoutItem = this.layoutItem
      return layoutItem.type === 'image' || layoutItem.type === 'video' || layoutItem.type === 'pdf'
    }
  },
  methods: {
    setFallbackImage (event: { target: { src: string } }): void {
      event.target.src = NotFoundImage
    },
    getItemValue(imgSrc): string {
      return imgSrc ? imgSrc : NotFoundImage
    },
    setFallbackVideo (event: { target: { poster: string } }): void {
      event.target.poster = NotFoundImage
    },
  }
})
