import { VALIDATIONS_NAMES } from '@/config/validation'
import { CountersState, Validations, ValidationsCounters } from './state'
import { TableItem } from '@/models/tableItem'
import { ValidationTypes } from '@/models/validation'

export const getTotalCountersPerValidator = (state: CountersState): TableItem[] => {
  if ((state.error != null && state.error.message) || (state.countersHistory[state.position] === undefined)) {
    return [] as TableItem[]
  }

  const validations: Validations = state.countersHistory[state.position].validations
  const tableItems: TableItem[] = []

  for (const key in validations) {
    if (!Object.values(ValidationTypes).includes(key as ValidationTypes)) {
      continue
    }

    tableItems.push({
      name: VALIDATIONS_NAMES[key],
      values: [
        { key: 'created', value: validations[key].created_count | 0 },
        { key: 'success', value: validations[key].succeeded_count | 0 },
        { key: 'failed', value: validations[key].failed_count | 0 }
      ]
    })
  }

  return tableItems
}

export const getTotalFailedCountersPerValidator = (state: CountersState): TableItem[] => {
  if ((state.error != null && state.error.message) || (state.countersHistory[state.position] === undefined)) {
    return [] as TableItem[]
  }

  const validations: Validations = state.countersHistory[state.position].validations
  const tableItems: TableItem[] = []

  for (const key in validations) {
    if (!Object.values(ValidationTypes).includes(key as ValidationTypes)) {
      continue
    }

    tableItems.push({
      name: VALIDATIONS_NAMES[key],
      values: [
        { key: 'declined', value: validations[key].failed_declined_count | 0 },
        { key: 'system_error', value: validations[key].failed_system_error_count | 0 },
        { key: 'expired', value: validations[key].failed_expired_count | 0 }
      ]
    })
  }

  return tableItems
}

export const getCountersLoading = (state: CountersState): boolean => {
  return state.countersLoading
}

export const getSelectedValidator = (state: CountersState): string => {
  return state.selectedValidator
}

export const getCountersHistory = (state: CountersState): ValidationsCounters[] => {
  return state.countersHistory
}

export const getValidatorCountMaxValue = (state: CountersState): number => {
  let max = 0
  for (let i = 0; i < state.countersHistory.length; i++) {
    const validationCounters = state.countersHistory[i].validations[state.selectedValidator]

    max = Math.max(max, validationCounters !== undefined ? validationCounters.created_count : 0)
  }

  return max
}
