import { ItemType, Card } from "../"
import { Validation } from "../../validation"
import jp from 'jsonpath'

export const faceSearchDetails = (validation: Validation): Card[] => {
    const faceSearchCard: Card = {
        title: 'Detalles de la validación',
        validation_status: validation.validation_status,
        first_section: [        
            {
                type: ItemType.IMAGE,
                label: 'Foto',
                value: jp.value(validation, '$..validation_inputs.validation_input_files[0]')
            }
        ]
    }

    return [faceSearchCard]
}