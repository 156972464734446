import { ACCOUNTS_BASE_API } from '@/config/constants'
import { AuthRequest } from '@/services/truora_api'
import { takeLatest, call, put, StrictEffect } from "redux-saga/effects";

export function * handleGetZapsignTemporalToken() : Generator<StrictEffect, void, any> {
  try {
    yield put({ type: 'zapsign/setZapsignTemporalTokenLoading', payload: true })

    const res = yield call(AuthRequest.get, '/v1/zapsign/token', {
      baseURL: ACCOUNTS_BASE_API,
    })

    if (res.status != 200) {
      throw new Error(`unexpected zapsign temporal token response status ${res.status}`)
    }

    if (!res.data.token) {
      throw new Error('zapsign temporal token not found in response')
    }

    yield put({ type: 'zapsign/setZapsignTemporalToken', payload: res.data.token })
    yield put({ type: 'zapsign/setZapsignTemporalTokenStatus', payload: "success" })
  } catch (e) {
    yield put({ type: 'zapsign/setZapsignTemporalTokenError', payload: e })
    yield put({ type: 'zapsign/setZapsignTemporalTokenStatus', payload: "failure" })
  } finally {
    yield put({ type: 'zapsign/setZapsignTemporalTokenLoading', payload: false })
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function * zapsign () {
  yield takeLatest('zapsign/getZapsignTemporalToken', handleGetZapsignTemporalToken)
}