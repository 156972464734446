import {ItemType} from "@/models/details";
import {DOC_TYPE, parseDate} from "@/models/details/details_data";
import {Validation} from "@/models/validation";
import jp from 'jsonpath'

export default function getDocumentBRNationalIDFields(validation: Validation): { type: ItemType, label: string, value: any }[] {
  return [
    {
      type: ItemType.ROW,
      label: 'Tipo de documento',
      value: DOC_TYPE[jp.value(validation, '$..details.document_details.document_type')]
    },
    {
      type: ItemType.ROW,
      label: 'Número de documento',
      value: jp.value(validation, '$..details.document_details.document_number')
    },
    {
      type: ItemType.ROW,
      label: 'Nombre',
      value: jp.value(validation, '$..details.document_details.name')
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de nacimiento',
      value: parseDate(jp.value(validation, '$..details.document_details.date_of_birth'), true)
    },

    {
      type: ItemType.ROW,
      label: 'Número de RG',
      value: jp.value(validation, '$..details.document_details.brazil_document.rg.rg_number')
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de expedición',
      value: parseDate(jp.value(validation, '$..details.document_details.issue_date'), true)
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de expiración',
      value: parseDate(jp.value(validation, '$..details.document_details.expiration_date'), true)
    },

  ]
}


