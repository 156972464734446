import { Validation, ValidationStatus } from '../validation'
import { getDetailsValidatorCards } from './details_data'
import { getGeneralValidatorCard } from './general_data'

export enum ItemType {
  MAIN_ROW = 'main-row',
  ROW = 'row',
  IMAGE = 'image',
  PDF = 'pdf',
  VIDEO = 'video',
  FRAUD_BUTTON = 'fraud-button',
  URL = 'url',
  VOICE_NOTE = 'voice-note'
}

export type LayoutItem = {
  type: ItemType
  subtitle?: string
  label: string
  labelURL?: string
  value?: string | string[] | boolean
  icon?: string
  mimeType?: string
  icon_color?: string
}

export type Card = {
  title: string,
  subtitle?: string,
  disclaimer?: string,
  validation_status?: ValidationStatus
  first_section?: LayoutItem[]
  second_section?: LayoutItem[]
  third_section?: LayoutItem[]
}

export type ValidationLayoutSpecification = {
  cards: Card[]
}

export const getValidationLayoutSpecification = (validation: Validation): ValidationLayoutSpecification => {
  // TODO: Add logic for specific details of other validators
  const cards = [
    getGeneralValidatorCard(validation)
  ]
  cards.push(...getDetailsValidatorCards(validation))

  return {
    cards
  }
}
