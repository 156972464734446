/* eslint-disable @typescript-eslint/no-empty-function */
import { AxiosRequestConfig } from 'axios'

// eslint-disable @typescript-eslint/explicit-module-boundary-types
export const fetchCurrentValidation = (): void => { }
export const fetchAllValidations = (params?: AxiosRequestConfig['params']): void => { }
export const fetchNextValidationsPage = (params?: AxiosRequestConfig['params']): void => { }
export const fetchPreviousValidationsPage = (params?: AxiosRequestConfig['params']): void => { }
export const fetchValidationByID = (): void => { }
export const storeValidationFeedback = (params?: AxiosRequestConfig['params']): void => { }
export const fetchValidationsHistory = (params?: AxiosRequestConfig["params"]): void => {};
