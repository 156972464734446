import { AuthState, Tenant } from './state'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { setAccountOnPath } from '@/services/user'

export const setUser = function (state: AuthState, user: CognitoUser): void {
  state.user = user
}

export const setTenant = function (state: AuthState, tenant: Tenant): void {
  state.tenant = tenant
}

export const setError = function (state: AuthState, error: Error): void {
  state.error = error
}

export const setStatus = function (state: AuthState, status: string): void {
  state.status = status
}

export const setAccount = function (state: AuthState, account: string): void {
  setAccountOnPath(account)
  state.account = account
}

export const setUsername = function (state: AuthState, username: string): void {
  state.username = username
}
