import { formatRFC3339 } from "date-fns";

export const urlParamsFromObject = function (obj: any = {}): URLSearchParams {
  const params = new URLSearchParams()
  const keys = Object.keys(obj)

  for (let i = 0; i < keys.length; i++) {
    const key = keys[i]
    const value = obj[key]

    params.append(key, value)
  }

  return params
}

// Formats a date string to RFC3339 format, taking into account the timezone
export const iso8601DateToLocalRFC3339 = (dateString: string): string => {
  const year = parseInt(dateString.substring(0, 4));
  const month = parseInt(dateString.substring(5, 7)) - 1; // JS months are zero-indexed
  const day = parseInt(dateString.substring(8, 10));
  const date = new Date(year, month, day);
  return formatRFC3339(date); // already takes care of the timezone
};
