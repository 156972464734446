import { render, staticRenderFns } from "./ItemVoiceNote.vue?vue&type=template&id=fa40d782&scoped=true"
import script from "./ItemVoiceNote.vue?vue&type=script&lang=ts"
export * from "./ItemVoiceNote.vue?vue&type=script&lang=ts"
import style0 from "./ItemVoiceNote.vue?vue&type=style&index=0&id=fa40d782&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa40d782",
  null
  
)

export default component.exports