export enum DeclinedReason {
  // --------------- OTP Validators reasons ---------------------------------

  WrongVerificationCode = "wrong_verification_code",

  // --------------- Face recognition validator reasons---------------------

  BadLightConditions = "bad_light_condition",
  InvalidImageFormat = "invalid_image_format",
  LivenessNotPassed = "liveness_verification_not_passed",
  NoFaceDetected = "no_face_detected",
  PhotoOfPhoto = "photo_of_photo",
  SimilarityThresholdNotPassed = "similarity_threshold_not_passed",
  PassiveLivenessNotPassed = "passive_liveness_verification_not_passed",
  FileFormatNotSupported = "file_format_not_supported",
  FacesAreTooSimilar = "faces_are_too_similar",
  VideoTooLarge = "video_too_large",
  InvalidVideoFile = "invalid_video_file",
  InvalidOrCorruptedImageFile = "invalid_or_corrupted_image_file",
  NotFoundInGovernmentDB = "document_number_not_found",

  // --------------- Identity questions validator reasons -----------------------

  TooManyIncorrectAnswers = "too_many_incorrect_answers",

  // ---------------- Document validation validator reasons ----------------------

  DifferentDocumentVersions = "document_has_different_versions",
  AgeAboveThreshold = "age_above_threshold",
  CheckInvalidInputs = "invalid_inputs_to_create_check",
  DocumentFrontSideIsAPhotocopy = "front_side_document_is_a_photocopy",
  DocumentHasExpired = "document_has_expired",
  DocumentIsAPhotocopy = "document_is_a_photocopy",
  DocumentIsAPhotoOfPhoto = "document_is_a_photo_of_photo",
  DocumentNotRecognized = "document_not_recognized",
  OcrNoTextDetected = "ocr_no_text_detected",
  DocumentReverseSideIsAPhotocopy = "reverse_side_document_is_a_photocopy",
  FaceNotFound = "face_not_detected",
  FrontDocumentNotFound = "front_document_not_found",
  GovernmentDatabaseCheckNotMatch = "data_not_match_with_government_database",
  GovernmentDatabaseCheckUnregistered = "document_unregistered",
  GovernmentDatabaseUnavailable = "government_database_unavailable",
  IdentityDeadPerson = "identity_belongs_to_dead_person",
  ImageFaceValidationNotPassed = "image_face_validation_not_passed",
  ImageTextValidationNotPassed = "image_text_validation_not_passed",
  InvalidCURP = "invalid_curp",
  InvalidTaxStatus = "invalid_tax_status",
  InvalidPostalCode = "invalid_postal_code",
  InvalidIssueDate = "invalid_issue_date",
  InvalidMRZ = "invalid_mrz",
  InvalidMRZFormat = "invalid_mrz_format",
  InvalidQRFormat = "invalid_qr_format",
  InvalidDocumentNumber = "invalid_document_number",
  ManualReviewFailed = "manual_review_failed",
  MissingDateOfBirth = "missing_date_of_birth",
  MissingDocumentNumber = "missing_document_number",
  MissingExpirationDate = "missing_expiration_date",
  MissingGender = "missing_gender",
  MissingInputs = "missing_inputs_to_create_check",
  MissingIssueDate = "missing_issue_date",
  MissingIssueNumber = "missing_issue_number",
  MissingMRZ = "missing_mrz",
  MissingNames = "missing_names",
  MissingStreet = "missing_street",
  MissingStateName = "missing_state_name",
  MissingMunicipalityName = "missing_municipality_name",
	MissingNeighborhood  = "missing_neighborhood",
	MissingPayLimitDate  = "missing_pay_limit_date",
	MissingPostalCode  = "missing_postal_code",
	MissingNationality = "missing_nationality",
  MissingNationalRegistrar = "missing_national_registrar",
  MissingProductionData = "missing_production_data",
  MissingText = "missing_text",
  NationalRegistrarInconsistency = "national_registrar_inconsistency",
  OCRFailure = "ocr_failed",
  ProductionDataInconsistency = "production_data_inconsistency",
  ProductionDataInvalidFormat = "production_data_invalid_format",
  ReverseDocumentNotFound = "reverse_document_not_found",
  Underage = "underage",
  ValidationsFailed = "validations_failed",

  PortraitPhotoIllegible = "portrait_photo_illegible",
	PerforationsIllegible = "perforations_illegible",
	MissingIssueState = "missing_issue_state",
	MissingIssueAgency = "missing_issue_agency",
	MissingIssueDeparment = "missing_issue_deparment",
	MissingCoatOfArms = "missing_coat_of_arms",
	MissingRegistrationNumber = "missing_registration_number",
  InconsistentParentNames = "inconsistent_parent_names",
	MissingCPF = "missing_cpf",
	MissingOriginDocument = "missing_origin_document",
	DateOfBirthDoesNotMatchWithPhoto = "date_of_birth_does_not_match_with_photo",
	InconsistentDelegateSignature = "inconsistent_delegate_signature",
	BlurryImage = "blurry_image",
	FakePicture = "portrait_photo_is_fake",
	IncompleteDocument = "incomplete_document",

  IrregularAlignments = "irregular_alignments",
	MissingAccents = "missing_accents",
	MissingSecurityElements = "missing_security_elements",
	IrregularFonts = "irregular_fonts",
	TracesOfTampering = "traces_of_tampering",
	MissingLegalInstitutePerforation = "missing_legal_institute_perforation",
	InvalidFormatForNaturallyField = "invalid_format_for_naturally_field",
	InvalidMinistry = "invalid_ministry",

  // --------------------EnterpriseData validator reasons----------------------------

  ItemNotFound = "item_not_found",
  ItemStatusDisabled = "item_status_disabled",

  // --------------------Voice Recognition validator reasons--------------------------

  AudioAlreadyUsed = "audio_file_already_used",
  AudioExceedsMaximumLength = "audio_exceeds_maximum_length",
  IncompleteEnrollment = "incomplete_enrollment_process",
  InvalidFileFormat = "invalid_file_format",
  NoVoiceDetected = "no_voice_detected",
  PhraseDoesNotMatch = "phrase_does_not_match",
  AsrPhraseDoesNotMatch = "asr_phrase_does_not_match",
  SpeakerTooLoud = "speaker_is_speaking_too_loud",
  SpeakerTooQuiet = "speaker_is_speaking_too_quiet",
  VoiceDoesNotMatch = "voice_does_not_match",

  	//---------------- Document Recognition validator reasons ----------------------.

	EmptyFile = "empty_input_file",
	MaxRetries = "max_retries_reached",


	//-------------- Document Recognition validator attachments reasons ------------

	FaceNotClear = "face_not_clear",
	DamagedDocument = "damaged_document",
	PossibleFraud = "possible_fraud",

  // --------------------Face Search validator reasons--------------------------------

  FraudsterMatchClientCollection = "fraudster_face_match_in_client_collection",
  FraudsterMatchGlobalCollection = "fraudster_face_match_in_global_collection",
  UserFaceMatchClientCollection = "user_face_match_in_client_collection",


	//--------------- Electronic signature validator reasons ---------------------

	MissingRequiredAttachedValidations = "missing_required_attached_validations",
	NotEnoughCreditsInSignatureIntegration = "not_enough_credits_in_electronic_signature",
	AuthenticationNotSuccessful = "electronic_signature_auth_fail",
	ZapsignObjectNotFound = "electronic_signature_object_not_found",
	SignerNameNotFound = "signer_name_not_found",
	InvalidSignerUpdateInDeletedDocument = "invalid_signer_update_in_deleted_document",
	SignerAlreadySignedDocument = "signer_already_signed_document",

	//--------------- Enter authorization reasons ---------------------

	Canceled = "canceled",

	//--------------- Non-linear flows reasons ---------------------

	RejectedAnswer = "rejected_answer",
}