import { ValidationTypes } from "../models/validation";
import { DeclinedReason } from "../models/declined_reason";

export const DECLINED_REASONS_NAMES: { [key in DeclinedReason]: string | undefined } = {
  // --------------- OTP Validators reasons ---------------------------------

  [DeclinedReason.WrongVerificationCode]: "Código errado",

  // --------------- Face recognition validator reasons---------------------

  [DeclinedReason.BadLightConditions]: "Malas condiciones de luz",
  [DeclinedReason.InvalidImageFormat]: "Formato de imagen inválido",
  [DeclinedReason.LivenessNotPassed]: "Prueba de vida fallida",
  [DeclinedReason.PassiveLivenessNotPassed]: "Prueba de vida fallida",
  [DeclinedReason.FileFormatNotSupported]: "Formato del archivo de validación no soportado",
  [DeclinedReason.FacesAreTooSimilar]: "La similitud de los rostros es muy alta",
  [DeclinedReason.NoFaceDetected]: "Rostro no detectado",
  [DeclinedReason.PhotoOfPhoto]: "Foto de foto",
  [DeclinedReason.SimilarityThresholdNotPassed]: "Similaridad entre rostros fallida",
  [DeclinedReason.VideoTooLarge]: "Video excede el tamaño",
  [DeclinedReason.InvalidVideoFile]: "Archivo de video inválido",
  [DeclinedReason.InvalidOrCorruptedImageFile]: "Archivo inválido o corrupto",
  [DeclinedReason.NotFoundInGovernmentDB]: "Número de documento no encontrado en la base de datos del gobierno",

  // --------------- Identity questions validator reasons -----------------------

  [DeclinedReason.TooManyIncorrectAnswers]: "Respuestas incorrectas",

  // ---------------- Document validation validator reasons ----------------------

  [DeclinedReason.DifferentDocumentVersions]: "Las caras del documento tienen diferentes versiones",
  [DeclinedReason.AgeAboveThreshold]: "Supera umbral de edad",
  [DeclinedReason.CheckInvalidInputs]: "Valores inválidos para crear una consulta",
  [DeclinedReason.DocumentFrontSideIsAPhotocopy]: "Lado frontal del documento es una fotocopia",
  [DeclinedReason.DocumentHasExpired]: "Documento expirado",
  [DeclinedReason.DocumentIsAPhotocopy]: "El documento es una fotocopia",
  [DeclinedReason.DocumentIsAPhotoOfPhoto]: "El documento es una foto de foto",
  [DeclinedReason.DocumentNotRecognized]: "Documento no reconocido",
  [DeclinedReason.OcrNoTextDetected]: "Texto no encontrado en la foto",
  [DeclinedReason.DocumentReverseSideIsAPhotocopy]: "Lado reverso del documento es una fotocopia",
  [DeclinedReason.FaceNotFound]: "Rostro no detectado",
  [DeclinedReason.FrontDocumentNotFound]: "Lado frontal del documento no encontrado",
  [DeclinedReason.GovernmentDatabaseCheckNotMatch]:
    "Datos no coinciden con la base de datos del gobierno",
  [DeclinedReason.GovernmentDatabaseCheckUnregistered]:
    "Document no registrado en la base de datos del gobierno",
  [DeclinedReason.GovernmentDatabaseUnavailable]:
    "La base de datos del gobierno no está disponible",
  [DeclinedReason.IdentityDeadPerson]: "La identidad pertenece a una persona fallecida",
  [DeclinedReason.ImageFaceValidationNotPassed]: "Validación de rostro no aprobada",
  [DeclinedReason.ImageTextValidationNotPassed]: "Validación de texto no aprobada",
  [DeclinedReason.InvalidCURP]: "CURP inválido",
  [DeclinedReason.InvalidTaxStatus]: "Estado fiscal inválido",
  [DeclinedReason.InvalidPostalCode]: "Código postal invalido",
  [DeclinedReason.InvalidIssueDate]: "Fecha de expedición inválida",
  [DeclinedReason.InvalidMRZ]: "MRZ inválido",
  [DeclinedReason.InvalidMRZFormat]: "Formato de MRZ inválido",
  [DeclinedReason.InvalidQRFormat]: "Formato QR no es válido",
  [DeclinedReason.InvalidDocumentNumber]: "Número de documento inválido",
  [DeclinedReason.ManualReviewFailed]: "Fallo por revisión manual",
  [DeclinedReason.MissingDateOfBirth]: "Fecha de nacimiento no encontrada",
  [DeclinedReason.MissingDocumentNumber]: "Número de documento no encontrado",
  [DeclinedReason.MissingExpirationDate]: "Fecha de expiración no encontrada",
  [DeclinedReason.MissingGender]: "Género no encontrado",
  [DeclinedReason.MissingInputs]: "No se encontraron todos los elementos para crear la consulta",
  [DeclinedReason.MissingIssueDate]: "Fecha de expedición no encontrada",
  [DeclinedReason.MissingIssueNumber]: "Número de expedición no encontrada",
  [DeclinedReason.MissingMRZ]: "MRZ no encontrado",
  [DeclinedReason.MissingNames]: "Nombres no encontrados",
  [DeclinedReason.MissingStreet]: "Calle no encontrada",
  [DeclinedReason.MissingStateName]: "Nombre de estado no encontrado",
  [DeclinedReason.MissingMunicipalityName]: "Nombre de municipalidad no encontrado",
  [DeclinedReason.MissingNeighborhood]: "Nombre de barrio no encontrado",
  [DeclinedReason.MissingPayLimitDate]: "Limite de fecha de pago no encontrada",
  [DeclinedReason.MissingPostalCode]: "Código postal no encontrado",
  [DeclinedReason.MissingNationality]: "Nacionalidad no encontrada",
  [DeclinedReason.MissingNationalRegistrar]: "Registro nacional no encontrado",
  [DeclinedReason.MissingProductionData]: "Datos de producción no encontrados",
  [DeclinedReason.MissingText]: "Texto incompleto",
  [DeclinedReason.NationalRegistrarInconsistency]: "Inconsistencias con registrador nacional",
  [DeclinedReason.OCRFailure]: "OCR fallido",
  [DeclinedReason.ProductionDataInconsistency]: "Datos de producción inconsistentes",
  [DeclinedReason.ProductionDataInvalidFormat]: "Datos de producción inválidos",
  [DeclinedReason.ReverseDocumentNotFound]: "Reverso de documento no encontrado",
  [DeclinedReason.Underage]: "Menor de edad",
  [DeclinedReason.ValidationsFailed]: "Validaciones fallidas",
  
  [DeclinedReason.PortraitPhotoIllegible]: "Foto ilegible",
  [DeclinedReason.PerforationsIllegible]: "Perforaciones ilegibles",
  [DeclinedReason.MissingIssueState]: "Estado emisor no encontrado",
  [DeclinedReason.MissingIssueAgency]: "Agencia emisora no encontrada",
  [DeclinedReason.MissingIssueDeparment]: "Departamento emisor no encontrado",
  [DeclinedReason.MissingCoatOfArms]: "Escudo de armas no encontrado",
  [DeclinedReason.MissingRegistrationNumber]: "Número de registro no encontrado",
  [DeclinedReason.InconsistentParentNames]: "Nombres de padres inconsistentes",
  [DeclinedReason.MissingCPF]: "CPF no encontrado",
  [DeclinedReason.MissingOriginDocument]: "Documento de origen no encontrado",
  [DeclinedReason.DateOfBirthDoesNotMatchWithPhoto]: "Fecha de nacimiento no coincide con la foto",
  [DeclinedReason.InconsistentDelegateSignature]: "Firma del delegado inconsistente",
  [DeclinedReason.BlurryImage]: "Imagen borrosa",
  [DeclinedReason.FakePicture]: "Imagen modificada o falsa",
  [DeclinedReason.IncompleteDocument]: "Documento incompleto",

  [DeclinedReason.IrregularAlignments]: "Alineaciones irregulares",
  [DeclinedReason.MissingAccents]: "Acentos no encontrados",
  [DeclinedReason.MissingSecurityElements]: "Elementos de seguridad no encontrados",
  [DeclinedReason.IrregularFonts]: "Fuentes irregulares",
  [DeclinedReason.TracesOfTampering]: "Rastros de manipulación",
  [DeclinedReason.MissingLegalInstitutePerforation]: "Documento con perforaciones irregulares",
  [DeclinedReason.InvalidFormatForNaturallyField]: "Formato inválido para el campo naturalmente",
  [DeclinedReason.InvalidMinistry]: "Ministerio inválido",

  // --------------------EnterpriseData validator reasons----------------------------

  [DeclinedReason.ItemNotFound]: "Registro no encontrado",
  [DeclinedReason.ItemStatusDisabled]: "Registro deshabilitado",

  // --------------------Voice Recognition validator reasons--------------------------

  [DeclinedReason.AudioAlreadyUsed]: "Archivo ya usado",
  [DeclinedReason.AudioExceedsMaximumLength]: "Audio excede el tamaño",
  [DeclinedReason.IncompleteEnrollment]: "Enrrolamiento incompleto",
  [DeclinedReason.InvalidFileFormat]: "Formato inválido",
  [DeclinedReason.NoVoiceDetected]: "Voz no detectada",
  [DeclinedReason.PhraseDoesNotMatch]: "Frase no coincide",
  [DeclinedReason.AsrPhraseDoesNotMatch]: "Frase no coincide con la frase solicitada",
  [DeclinedReason.SpeakerTooLoud]: "Voz muy alta",
  [DeclinedReason.SpeakerTooQuiet]: "Voz muy baja",
  [DeclinedReason.VoiceDoesNotMatch]: "Voz no coincide",

  //---------------- Document Recognition validator reasons ----------------------.

  [DeclinedReason.EmptyFile]: "Archivo vacío",
  [DeclinedReason.MaxRetries]: "Cantidad máxima de reintentos alcanzada",

  //-------------- Document Recognition validator attachments reasons ------------

  [DeclinedReason.FaceNotClear]: "Rostro en el documento no es claro",
  [DeclinedReason.DamagedDocument]: "Documento deteriorado",
  [DeclinedReason.PossibleFraud]: "Posible fraude",


  // --------------------- Face Search validator reasons------------------------------

  [DeclinedReason.FraudsterMatchClientCollection]:
    "Cara de fraudulento coincide con colección del cliente",
  [DeclinedReason.FraudsterMatchGlobalCollection]:
    "Cara de fraudulento coincide con colección global",
  [DeclinedReason.UserFaceMatchClientCollection]:
    "Cara de usuario coincide con colección del cliente",

	//--------------- Electronic signature validator reasons ---------------------

  [DeclinedReason.MissingRequiredAttachedValidations]: "Faltan validaciones adjuntas requeridas",
  [DeclinedReason.NotEnoughCreditsInSignatureIntegration]: "No hay suficientes créditos en la integración de firmas",
  [DeclinedReason.AuthenticationNotSuccessful]: "Autenticación fallida",
  [DeclinedReason.ZapsignObjectNotFound]: "documento o plantilla no encontrada",
  [DeclinedReason.SignerNameNotFound]: "Nombre del firmante no encontrado",
  [DeclinedReason.InvalidSignerUpdateInDeletedDocument]: "Actualización de firmante no válida en documento eliminado",
  [DeclinedReason.SignerAlreadySignedDocument]: "Documento ya ha sido firmado",

	//--------------- Enter authorization reasons ---------------------

  [DeclinedReason.Canceled]: "Cancelado",

	//--------------- Non-linear flows reasons ---------------------

  [DeclinedReason.RejectedAnswer]: "Respuesta rechazada",
};

export const VALIDATION_DECLINED_REASONS: {
  [key in ValidationTypes]: (DeclinedReason | undefined)[];
} = {
  [ValidationTypes.PhoneVerification]: [DeclinedReason.WrongVerificationCode],
  [ValidationTypes.EmailVerification]: [DeclinedReason.WrongVerificationCode],
  [ValidationTypes.DocumentValidation]: [
    DeclinedReason.AgeAboveThreshold,
    DeclinedReason.CheckInvalidInputs,
    DeclinedReason.DocumentFrontSideIsAPhotocopy,
    DeclinedReason.DocumentHasExpired,
    DeclinedReason.DocumentIsAPhotocopy,
    DeclinedReason.DocumentIsAPhotoOfPhoto,
    DeclinedReason.DocumentNotRecognized,
    DeclinedReason.DocumentReverseSideIsAPhotocopy,
    DeclinedReason.FaceNotFound,
    DeclinedReason.FrontDocumentNotFound,
    DeclinedReason.GovernmentDatabaseCheckNotMatch,
    DeclinedReason.GovernmentDatabaseCheckUnregistered,
    DeclinedReason.GovernmentDatabaseUnavailable,
    DeclinedReason.IdentityDeadPerson,
    DeclinedReason.ImageFaceValidationNotPassed,
    DeclinedReason.ImageTextValidationNotPassed,
    DeclinedReason.InvalidCURP,
    DeclinedReason.InvalidIssueDate,
    DeclinedReason.InvalidMRZ,
    DeclinedReason.InvalidMRZFormat,
    DeclinedReason.ManualReviewFailed,
    DeclinedReason.MissingDateOfBirth,
    DeclinedReason.MissingDocumentNumber,
    DeclinedReason.MissingExpirationDate,
    DeclinedReason.MissingGender,
    DeclinedReason.MissingInputs,
    DeclinedReason.MissingIssueDate,
    DeclinedReason.MissingMRZ,
    DeclinedReason.MissingNames,
    DeclinedReason.MissingNationality,
    DeclinedReason.MissingNationalRegistrar,
    DeclinedReason.MissingProductionData,
    DeclinedReason.MissingText,
    DeclinedReason.NationalRegistrarInconsistency,
    DeclinedReason.OCRFailure,
    DeclinedReason.ProductionDataInconsistency,
    DeclinedReason.ProductionDataInvalidFormat,
    DeclinedReason.Underage,
    DeclinedReason.ValidationsFailed,
  ],
  [ValidationTypes.FaceRecognition]: [
    DeclinedReason.LivenessNotPassed,
    DeclinedReason.SimilarityThresholdNotPassed,
    DeclinedReason.NoFaceDetected,
    DeclinedReason.BadLightConditions,
    DeclinedReason.InvalidImageFormat,
    DeclinedReason.VideoTooLarge,
    DeclinedReason.PhotoOfPhoto,
    DeclinedReason.InvalidVideoFile,
  ],
  [ValidationTypes.VoiceRecognition]: [
    DeclinedReason.AudioAlreadyUsed,
    DeclinedReason.NoVoiceDetected,
    DeclinedReason.VoiceDoesNotMatch,
    DeclinedReason.PhraseDoesNotMatch,
    DeclinedReason.IncompleteEnrollment,
    DeclinedReason.SpeakerTooLoud,
    DeclinedReason.SpeakerTooQuiet,
    DeclinedReason.AudioExceedsMaximumLength,
    DeclinedReason.InvalidFileFormat,
  ],
  [ValidationTypes.IdentityQuestions]: [DeclinedReason.TooManyIncorrectAnswers],
  [ValidationTypes.EnterpriseData]: [
    DeclinedReason.ItemNotFound,
    DeclinedReason.ItemStatusDisabled,
  ],
  [ValidationTypes.FaceSearch]: [
    DeclinedReason.FraudsterMatchClientCollection,
    DeclinedReason.FraudsterMatchGlobalCollection,
  ],
  [ValidationTypes.ElectronicSignature]: [],
};
