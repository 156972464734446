
import Vue from "vue";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import Modal from "@bit/truora.pneuma.modal";
import TextField from "@bit/truora.pneuma.text-field";
import Btn from "@bit/truora.pneuma.btn";
import { getCountries, Country } from "../../config/countries";
import { VAlIDATOR_FEEDBACK_FEEDBACK_REASONS, Language } from "@/models/feedback_reasons";
import { EMAIL_REGEX } from "@/config/constants";

const emailRules = [
  (v: string): boolean | string => !!v || "Correo requerido",
  (v: string): boolean | string => EMAIL_REGEX.test(v) || "Favor ingresar un correo valido",
];
const phoneRules = [
  (v: string): boolean | string => v.length > 0 || "Número de teléfono requerido",
  (v: string): boolean | string =>
    (Number.isInteger(Number(v)) && Number(v) >= 0) || "Favor ingresar solo dígitos",
];

export default Vue.extend({
  components: {
    Modal,
    TextField,
    Btn,
  },
  data() {
    return {
      valid: false,
      country: "CO",
      reason: "fraudster",
      description: "",
      email: "",
      phone: "",
      descriptionLimit: 200,
      emailRules,
      phoneRules,
    };
  },
  computed: {
    ...mapGetters("validations", [
      "getCurrentValidation",
      "getValidationFeedbackSuccess",
      "getValidationFeedbackLoading",
    ]),
    ...mapState("validations", ["reportEnrollmentID"]),
    countries(): Country[] {
      return getCountries();
    },
    reasons(): Language[] {
      const validation = this.getCurrentValidation!;

      return VAlIDATOR_FEEDBACK_FEEDBACK_REASONS[validation.type];
    },
    descriptionRules() {
      return [
        (v: string): boolean | string => v.length > 0 || "Descripción requerida",
        (v: string): string | boolean =>
          v.length <= this.descriptionLimit || `Máximo ${this.descriptionLimit} caracteres`,
      ];
    },
  },
  methods: {
    ...mapActions("validations", ["storeValidationFeedback"]),
    ...mapMutations("validations", [
      "setValidationFeedbackSuccess",
      "setValidationFeedbackReportEnrollmentID",
      "setError",
    ]),
    handleReport(): void {
      const {
        account_id,
        validation_id,
        type: validation_type,
        validation_status,
        declined_reason,
      } = this.getCurrentValidation!;

      this.$gtag.event("fraud_feedback_sent", {
        validation_type,
        validation_status,
        declined_reason,
      });

      this.storeValidationFeedback({
        account_id: account_id,
        validation_id: validation_id,
        description: this.description,
        reasons: this.reason,
        phone: this.phone,
        email: this.email,
      });
    },
    openModal(): void {
      const {
        type: validation_type,
        validation_status,
        declined_reason,
      } = this.getCurrentValidation!;

      this.$gtag.event("report_fraud_button_clicked", {
        validation_type,
        validation_status,
        declined_reason,
      });

      const modal = this.$refs.modal as Modal;
      modal.openModal();
    },
  },
  beforeDestroy(): void {
    this.setValidationFeedbackSuccess(false);
    this.setValidationFeedbackReportEnrollmentID("");
  },
});
