import { render, staticRenderFns } from "./ItemRow.vue?vue&type=template&id=1bc0a894&scoped=true"
import script from "./ItemRow.vue?vue&type=script&lang=ts"
export * from "./ItemRow.vue?vue&type=script&lang=ts"
import style0 from "./ItemRow.vue?vue&type=style&index=0&id=1bc0a894&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bc0a894",
  null
  
)

export default component.exports