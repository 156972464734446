import { ItemType, Card, LayoutItem } from '..'
import { Validation } from '../../validation'
import jp from 'jsonpath'

export const electronicSignatureDetails = (validation: Validation): Card[] => {
  const firstSection = [
    {
      type: ItemType.ROW,
      label: 'Nombre',
      value: jp.value(validation, '$..validation_inputs.name')
    },
    {
      type: ItemType.ROW,
      label: 'Tipo de documento',
      value: jp.value(validation, '$..validation_inputs.zapsign_document_type')
    },
    {
      type: ItemType.ROW,
      label: 'ID de Integración',
      value: jp.value(validation, '$..validation_inputs.customer_integration_id')
    }
  ] as LayoutItem[]

  const signedURL = jp.value(validation, '$..details.electronic_signature_details.signed_document_url')
  if (signedURL && signedURL!=="") {
    firstSection.push({
      type: ItemType.URL,
      label: 'URL',
      value: jp.value(validation, '$..details.electronic_signature_details.original_document_url'),
      icon: 'mdi-open-in-new',
      labelURL: 'Ver documento original',
    })
    firstSection.push({
      type: ItemType.URL,
      label: 'URL',
      value: jp.value(validation, '$..details.electronic_signature_details.signed_document_url'),
      icon: 'mdi-open-in-new',
      labelURL: 'Ver documento firmado',
    })
    firstSection.push({
      type: ItemType.URL,
      label: "URL",
      value: "https://app.zapsign.co/conta/documentos/" + jp.value(validation, "$..details.electronic_signature_details.document_id"),
      icon: "mdi-open-in-new",
      labelURL: "Ver documento en Zapsign",
    })
  }

  const cards = [
    {
      title: 'Detalles de la validación',
      subtitle: 'Información de la firma electrónica',
      first_section: firstSection,
      second_section: [
        {
          type: ItemType.IMAGE,
          label: 'Firma',
          value: jp.value(validation, '$..details.electronic_signature_details.signature_image_url')
        }
      ] as LayoutItem[]
    }
  ]  

  return cards
}
