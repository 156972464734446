
import Vue from "vue"
import AppLogo from "@/layouts/AppLogo.vue"

export default Vue.extend({
  components: {
    AppLogo,
  },
  props: {
    logoUrl: {
      type: String,
      default: "https://account.truora.com/",
    },
  },
  methods: {
    openMenu(): void {
      this.$emit("openMenu", true)
    },
  },
})
