import { VALIDATIONS_FAILURE_STATUS, VALIDATIONS_NAMES } from '@/config/validation'
import { ItemType, Card, LayoutItem } from '.'
import { Validation, ValidationStatus, ManualReviewStatus, ValidationTypes } from '../validation'
import jp from 'jsonpath'
import { DECLINED_REASONS_NAMES } from '@/config/declined_reason'
import { DETAILS_ICONS, DETAILS_ICONS_COLOR } from './details_data'
import { CHECKS_DASHBOARD } from "@/config/constants";

const getTimeValueBetweenDates = (startDate, endDate): string => {
  let chosenStartDate = startDate
  let chosenEndDate = endDate

  if (typeof startDate === 'string') {
    chosenStartDate = new Date(startDate)
  }

  if (typeof endDate === 'string') {
    chosenEndDate = new Date(endDate)
  }

  const difference = Math.round((chosenEndDate.getTime() - chosenStartDate.getTime()) / 1000)
  let secondsValue = 'segundos'

  if (difference === 1) {
    secondsValue = 'segundo'
  }

  return `${difference} ${secondsValue}`
}

const getFormattedDate = (date: Date): string => (
  date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  })
)

const addConfidenceScoreToCard = (card: Card, validation: Validation) => {
  const confidenceScore = jp.value(validation, '$..details.face_recognition_validations.confidence_score') || ""

  if (confidenceScore !== "" && card?.first_section) {
    card.first_section.push({
      type: ItemType.MAIN_ROW,
      label: 'Similitud',
      value: confidenceScore
    })
  }
}

const addManualReviewStatusToCard = (card: Card, validation: Validation) => {
  const manualReviewStatus = jp.value(validation, '$..manual_review_status') || ""

  if (manualReviewStatus === ManualReviewStatus.Started && card?.first_section){
    card.first_section.push({
      type: ItemType.MAIN_ROW,
      label: '',
      value: 'Revisión manual',
      icon: DETAILS_ICONS[ValidationStatus.Pending],
      icon_color: DETAILS_ICONS_COLOR[ValidationStatus.Pending]
    })

    return
  }

  if (manualReviewStatus === ManualReviewStatus.Performed && card?.first_section){
    card.first_section.push({
      type: ItemType.MAIN_ROW,
      label: '',
      value: 'Manual review',
      icon: DETAILS_ICONS[ValidationStatus.Success],
      icon_color: DETAILS_ICONS_COLOR[ValidationStatus.Success]
    })
  }
}

const addTimesToCard = (card: Card, validation: Validation) => {
  if (validation.processing_start_date !== undefined && validation.processing_finish_date !== undefined && card?.first_section) {
    card.first_section.push({
      type: ItemType.MAIN_ROW,
      label: 'Tiempo de procesamiento de Truora',
      value: getTimeValueBetweenDates(validation.processing_start_date, validation.processing_finish_date),
      icon: 'mdi-clock-outline'
    })
  }

  if (validation.processing_finish_date !== undefined && card.second_section) {
    card.second_section.push({
      type: ItemType.MAIN_ROW,
      label: 'Tiempo de interacción con el usuario',
      value: getTimeValueBetweenDates(validation.creation_date, validation.processing_finish_date),
      icon: 'mdi-clock-outline'
    })
  }
}

const succeededValidation = (validation: Validation): Card => {
  const layout = {
    title: VALIDATIONS_NAMES[jp.value(validation, '$..type')],
    validation_status: validation.validation_status,
    first_section: [
      {
        type: ItemType.MAIN_ROW,
        label: 'ID cuenta',
        value: jp.value(validation, '$..account_id')
      }
    ] as LayoutItem[],
    second_section: [
      {
        type: ItemType.MAIN_ROW,
        label: 'ID validación',
        value: jp.value(validation, '$..validation_id')
      }
    ] as LayoutItem[],
    third_section: [
      {
        type: ItemType.MAIN_ROW,
        label: 'Fecha y hora de validación',
        value: getFormattedDate(jp.value(validation, '$..creation_date'))
      }
    ] as LayoutItem[]
  }

  if ( validation.type == ValidationTypes.FaceRecognition ) {
    layout['third_section'].push({
      type: ItemType.FRAUD_BUTTON,
      label: 'Reportar fraude',
      icon: 'mdi-alert-circle-outline'
    })
  }

  addTimesToCard(layout, validation)

  if (validation.type == ValidationTypes.DocumentValidation && validation?.details?.background_check?.check_id) {
    layout['third_section'].push({
      type: ItemType.URL,
      label: 'Check',
      icon: 'mdi-open-in-new',
      labelURL: 'Check URL',
      value: CHECKS_DASHBOARD + "/#/dashboard/check/custom/" + jp.value(validation, '$..check_id')
    })
  }

  addManualReviewStatusToCard(layout, validation)
  addConfidenceScoreToCard(layout, validation)

  return layout
}

const failedValidation = (validation: Validation): Card => {
  const layout = {
    title: VALIDATIONS_NAMES[jp.value(validation, '$..type')],
    validation_status: validation.validation_status,
    first_section: [
      {
        type: ItemType.MAIN_ROW,
        label: 'ID cuenta',
        value: jp.value(validation, '$..account_id')
      }
    ] as LayoutItem[],
    second_section: [
      {
        type: ItemType.MAIN_ROW,
        label: 'ID validación',
        value: jp.value(validation, '$..validation_id')
      }
    ] as LayoutItem[],
    third_section: [
      {
        type: ItemType.MAIN_ROW,
        label: 'Fecha y hora de validación',
        value: getFormattedDate(jp.value(validation, '$..creation_date'))
      }
    ] as LayoutItem[]
  }

  if ( validation.type == ValidationTypes.FaceRecognition ) {
    layout['third_section'].push({
      type: ItemType.FRAUD_BUTTON,
      label: 'Reportar fraude',
      icon: 'mdi-alert-circle-outline'
    })
  }

  if (validation.failure_status !== undefined) {
    layout.first_section.push({
      type: ItemType.MAIN_ROW,
      label: 'Estado del fallo',
      value: VALIDATIONS_FAILURE_STATUS[jp.value(validation, '$..failure_status')]
    })
  }

  if (validation.declined_reason !== undefined) {
    layout.second_section.push({
      type: ItemType.MAIN_ROW,
      label: 'Razón del fallo',
      value: DECLINED_REASONS_NAMES[jp.value(validation, '$..declined_reason')]
    })
  }

  addTimesToCard(layout, validation)

  if (validation.type == ValidationTypes.DocumentValidation && validation?.details?.background_check?.check_id) {
    layout['third_section'].push({
      type: ItemType.URL,
      label: 'Check',
      icon: 'mdi-open-in-new',
      labelURL: 'Check URL',
      value: CHECKS_DASHBOARD + "/#/dashboard/check/custom/" + jp.value(validation, '$..check_id')
    })
  }

  addManualReviewStatusToCard(layout, validation)
  addConfidenceScoreToCard(layout, validation)

  return layout
}

const GENERAL_CARD_GENERATOR: { [key in ValidationStatus]: (validation: Validation) => Card } = {
  [ValidationStatus.Success]: succeededValidation,
  [ValidationStatus.Pending]: succeededValidation,
  [ValidationStatus.Failure]: failedValidation
}

export const getGeneralValidatorCard = (validation: Validation): Card => {
  return GENERAL_CARD_GENERATOR[validation.validation_status](validation)
}
