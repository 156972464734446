import { DeclinedReasonCounters, MetricsState, Percentiles, ValidationMetric, ValidationsDeclinedReasonMetrics } from './state'
import { PercentileTableItem } from '@/models/percentileTableItem'
import { ValidationStatus, ValidationTypes } from '@/models/validation'
import { DeclinedReason } from '@/models/declined_reason'
import * as percentilCalculator from 'percentile';

const statusLabels = {
  success: 'exitosas',
  failure: 'fallidas'
}

const metricsLabels = {
  processing_time: 'Procesamiento Truora',
  interaction_time: 'Interacción con el usuario'
}

const percentilesToShow = [Percentiles.p25, Percentiles.p50, Percentiles.p75, Percentiles.p95, Percentiles.p99]

const percentilesToCalculate: number[] = [
  parseInt(Percentiles.p25),
  parseInt(Percentiles.p50),
  parseInt(Percentiles.p75),
  parseInt(Percentiles.p95),
  parseInt(Percentiles.p99)]

export const getTableItemPercentiles = (state: MetricsState): PercentileTableItem[] => {
  if (state.validationsTimes === null || state.validationsTimes === undefined) {
    return [] as PercentileTableItem[]
  }

  const validatorMetrics = state.validationsTimes[state.validator]
  if (validatorMetrics === undefined) {
    return [] as PercentileTableItem[]
  }

  const tableItems: PercentileTableItem[] = []

  let status: ValidationStatus
  for (status in validatorMetrics) {
    const validatorMetric = validatorMetrics[status]

    for (const metric in validatorMetric) {
      const percentileValues = buildPercentileValues(validatorMetric, metric)

      tableItems.push({
        name: metricsLabels[metric] + ' (' + statusLabels[status] + ')',
        cells: percentileValues
      })
    }
  }

  return tableItems
}

function buildPercentileValues(validationMetric: ValidationMetric, metric: string): { key: string, value: number }[] {
  const percentilResult: number | number[] = percentilCalculator.default(
    percentilesToCalculate,
    validationMetric[metric]
  )

  const percentileValues: { key: string, value: number }[] = []

  for (const index in percentilesToShow) {

    percentileValues.push({
      key: percentilesToShow[index],
      value: Math.round(percentilResult[index] / 1000)
    })
  }

  return percentileValues
}

export const getDeclinedReasonsHistory = (state: MetricsState): ValidationsDeclinedReasonMetrics[] => {
  return state.validationDeclinedReasonsHistory
}

export const getDeclinedReasonsLoading = (state: MetricsState): boolean => {
  return state.validationDeclinedReasonsLoading
}

const getMaxValuePerValidator = (validatorMetrics: Record<DeclinedReason, DeclinedReasonCounters>): number => {
  const reasons = Object.keys(validatorMetrics)
  let max = 0

  for (let i = 0; i < reasons.length; i++) {
    if (!validatorMetrics[reasons[i]] || !validatorMetrics[reasons[i]].counts) {
      continue
    }

    max = Math.max(max, validatorMetrics[reasons[i]].counts)
  }

  return max
}

export const getDeclinedReasonsCountMaxValue = (state: MetricsState): (selectedValidator: ValidationTypes) => number => {
  return function (selectedValidator) {
    let max = 0

    for (let i = 0; i < state.validationDeclinedReasonsHistory.length; i++) {
      const validationDeclinedReasons = state.validationDeclinedReasonsHistory[i].metrics
      if (!validationDeclinedReasons) {
        continue
      }

      const validatorMetrics = validationDeclinedReasons[selectedValidator]
      if (!validatorMetrics) {
        continue
      }

      max = Math.max(max, getMaxValuePerValidator(validatorMetrics))
    }

    return max
  }
}
