import {Validation} from "@/models/validation";
import {ItemType} from "@/models/details";
import jp from 'jsonpath'
import {DOC_TYPE, parseDate} from "@/models/details/details_data";

export default function getMXInvoiceFields(validation: Validation): { type: ItemType, label: string, value: any }[] {
  return [
    {
      type: ItemType.ROW,
      label: 'Tipo de documento',
      value: DOC_TYPE[jp.value(validation, '$..details.document_details.document_type')]
    },
    {
      type: ItemType.ROW,
      label: 'Nombre',
      value: jp.value(validation, '$..details.document_details.name')
    },
    {
      type: ItemType.ROW,
      label: 'Calle',
      value: jp.value(validation, '$..details.document_details.street')
    },
    {
      type: ItemType.ROW,
      label: 'Número exterior',
      value: jp.value(validation, '$..details.document_details.mexico_document.exterior_number')
    },
    {
      type: ItemType.ROW,
      label: 'Número interior',
      value: jp.value(validation, '$..details.document_details.mexico_document.interior_number')
    },
    {
      type: ItemType.ROW,
      label: 'Colonia',
      value: jp.value(validation, '$..details.document_details.mexico_document.neighborhood')
    },
    {
      type: ItemType.ROW,
      label: 'Municipio',
      value: jp.value(validation, '$..details.document_details.mexico_document.municipality_name')
    },
    {
      type: ItemType.ROW,
      label: 'Estado',
      value: jp.value(validation, '$..details.document_details.mexico_document.state_name')
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de expedición',
      value: parseDate(jp.value(validation, '$..details.document_details.issue_date'), false)
    },
    {
      type: ItemType.ROW,
      label: 'Fecha limite de pago',
      value: parseDate(jp.value(validation, '$..details.document_details.pay_limit_date'), false)
    },
  ]
}
