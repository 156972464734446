
import Vue from "vue"

export default Vue.extend({
  props: {
    logoUrl: {
      type: String,
      default: "https://account.truora.com/",
    },
  },
  methods: {
    handleRedirection(url: string): void {
      window.location.href = url
    },
  },
})
