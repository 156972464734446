
import Vue from "vue"
import ValidationsDropdown from '@/components/ValidationsDropdown.vue'
import { mapMutations } from "vuex"

function getNewDate () {
  const today = new Date()
  const date = new Intl.DateTimeFormat('en-US').format(today)
  const time =
    today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()

  return date + ' ' + time
}

export default Vue.extend({
  components: {
    ValidationsDropdown
  },
  data() {
    return {
      loading: false,
      updateMessage: 'Actualizado hace unos segundos',
      updateDate: getNewDate(),
      counter: 0,
      itemSelected: '',
      metricsItems: [
        { key: 'Mes actual', value: 0 },
        { key: 'Mes anterior', value: 1 },
        { key: 'Hace dos meses', value: 2 }
      ]
    }
  },
  props: {
    items: {
      required: true,
      type: Array as () => string[],
    },
  },
  methods: {
    ...mapMutations("counters", ["setPosition"]),
    getDropDownItem (value: string) {
      this.itemSelected = value
    },
    getMessage () {
      switch (true) {
        case this.counter < 30:
          return (this.updateMessage = 'Actualizado hace unos segundos')

        case this.counter < 60:
          return (this.updateMessage = 'Actualizado hace 30 segundos')

        case this.counter < 120:
          return (this.updateMessage = 'Actualizado hace 1 minuto')

        case this.counter < 300:
          return (this.updateMessage = 'Actualizado hace unos minutos')

        default:
          return (this.updateMessage =
            'Última vez actualizado: ' + this.updateDate)
      }
    },
    processEvent () {
      this.loading = true
      this.counter = 0
      this.updateDate = getNewDate()

      const t = setInterval(() => {
        this.loading = false

        for (const i in this.metricsItems) {
          if (this.metricsItems[i].key === this.itemSelected) {
            this.setPosition(this.metricsItems[i].value)
            this.$emit('setPreviousMonth', this.metricsItems[i].value)
          }
        }

        clearInterval(t)
      }, 2000) // Change this loading time if it's needed
    }
  },
  mounted () {
    setInterval(() => {
      this.counter = this.counter + 1
      this.getMessage()
    }, 1000)
    this.itemSelected = this.items[0]
  }

})
