
import { Vue, Component, Prop } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
import { Counters, CountersState, ValidationsCounters } from '@/store/modules/counters/state'
import { VALIDATION_DECLINED_REASONS, DECLINED_REASONS_NAMES } from '@/config/declined_reason'
import { ValidationTypes } from '@/models/validation'
import { ValidationsDeclinedReasonMetrics } from '@/store/modules/metrics/state'
import { DeclinedReason } from '@/models/declined_reason'
import Bar, { BarChartType } from '@bit/truora.pneuma.bar'

const counters = namespace('counters')
const metrics = namespace('metrics')

const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

interface DeclinedReasonsBarChartData{
  label:string,
  data: number[],
  backgroundColor:string
}

@Component({
  components: {
    Bar
  }
})
export default class ValidationsBarCharts extends Vue {
  @Prop({ required: true })
  selectedItem

  @counters.Getter
  private getCountersHistory!: ValidationsCounters[];

  @counters.Getter
  private getValidatorCountMaxValue!: number;

  @counters.Getter
  getSelectedValidator!: string

  @counters.Getter
  getCountersLoading!: (state: CountersState) => boolean;

  @metrics.Getter
  private getDeclinedReasonsHistory!: ValidationsDeclinedReasonMetrics[];

  @metrics.Getter
  private getDeclinedReasonsCountMaxValue!:(selectedValidator:ValidationTypes) => number;

  @metrics.Getter
  getDeclinedReasonsLoading!: boolean;

  getHistoryBarChartLabels () {
    return this.getCountersHistory.map(x => {
      const month = parseInt(x.period.split('-')[1])
      if (isNaN(month) || month <= 0 || month > 12) {
        return undefined
      }

      return monthNames[month - 1]
    })
  }

  calculateStepSize (maxValue: number) {
    return (maxValue >= 1000) ? ((maxValue / 1000) * 100).toFixed(0) : 100
  }

  configureBarChartValues (barChartType:BarChartType, currentValidatorCounters:Counters) {
    barChartType.data[0].data.push(currentValidatorCounters ? currentValidatorCounters.created_count : 0)
    barChartType.data[1].data.push(currentValidatorCounters ? currentValidatorCounters.succeeded_count : 0)
    barChartType.data[2].data.push(currentValidatorCounters ? currentValidatorCounters.failed_declined_count : 0)
    barChartType.data[3].data.push(currentValidatorCounters ? currentValidatorCounters.failed_system_error_count : 0)
    barChartType.data[4].data.push(currentValidatorCounters ? currentValidatorCounters.failed_expired_count : 0)
  }

  get getValidationHistoryBarChartConfig (): BarChartType {
    const barChartType :BarChartType = {
      width: '34.375rem',
      height: '25rem',
      labels: this.getHistoryBarChartLabels(),
      data: [
        {
          label: 'Creadas',
          data: [],
          backgroundColor: '#0701C5'
        },
        {
          label: 'Exitosas',
          data: [],
          backgroundColor: '#0084F4'
        },
        {
          label: 'Rechazadas',
          data: [],
          backgroundColor: '#66B5F8'
        },
        {
          label: 'Error del sistema',
          data: [],
          backgroundColor: '#6300FF'
        },
        {
          label: 'Expiradas',
          data: [],
          backgroundColor: '#9D62FB'
        }
      ],
      options: {
        layout: {
          padding: {
            top: 20,
            right: 20,
            left: 20,
            bottom: 5
          }
        },
        legend: {
          position: 'top',
          labels: {
            fontColor: '#1F026D',
            fontFamily: 'Montserrat'
          }
        },
        title: {
          display: true,
          text: 'Historial de validaciones',
          fontColor: '#1F026D',
          fontFamily: 'Montserrat',
          fontSize: 20
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: '',
                fontColor: '#1F026D',
                fontFamily: 'Montserrat',
                fontStyle: 'Bold',
                fontSize: 16
              },
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Validaciones totales',
                fontColor: '#1F026D',
                fontFamily: 'Montserrat',
                fontStyle: 'Bold',
                fontSize: 16
              },
              ticks: {
                stepSize: this.calculateStepSize(this.getValidatorCountMaxValue),
                min: 0,
                max: this.getValidatorCountMaxValue
              },
              gridLines: {
                borderDash: [5, 5]
              }
            }
          ]
        }
      }
    }

    for (let i = 0; i < this.getCountersHistory.length; i++) {
      this.configureBarChartValues(barChartType, this.getCountersHistory[i].validations[this.getSelectedValidator])
    }

    return barChartType
  }

  getDeclinedReasonsLabelsData () {
    const colors = ['#0701C5', '#0084F4', '#66B5F8', '#6300FF', '#9D62FB']

    return VALIDATION_DECLINED_REASONS[this.selectedItem].map((reason:DeclinedReason, i:number) => ({
      label: DECLINED_REASONS_NAMES[reason],
      data: [],
      backgroundColor: colors[i % colors.length]
    }))
  }

  getDeclinedReasonsBarChartLabels () {
    return this.getDeclinedReasonsHistory.map(x => {
      const month = parseInt(x.period.split('-')[1])
      if (isNaN(month) || month <= 0 || month > 12) {
        return undefined
      }

      return monthNames[month - 1]
    })
  }

  getIncludedDeclinedReasons ():Record<string, number[]> {
    const includedReasonsMap:Record<string, number[]> = {}
    const declinedReasonsForValidation = VALIDATION_DECLINED_REASONS[this.selectedItem]
    if (!declinedReasonsForValidation) {
      return includedReasonsMap
    }

    declinedReasonsForValidation.forEach((reason:DeclinedReason, reasonIndex:number) => {
      const currentDeclinedReasonCounters:number[] = []
      let isIncluded = false

      for (let i = 0; i < this.getDeclinedReasonsHistory.length; i++) {
        const currentMetrics = this.getDeclinedReasonsHistory[i].metrics[this.getSelectedValidator]

        if (currentMetrics === undefined || currentMetrics[reason] === undefined) {
          currentDeclinedReasonCounters.push(0)
          continue
        }

        isIncluded = true
        currentDeclinedReasonCounters.push(currentMetrics[reason].counts)
      }

      if (isIncluded) {
        includedReasonsMap[reason] = currentDeclinedReasonCounters
      }
    })

    return includedReasonsMap
  }

  getDeclinedReasonsBarChartData ():DeclinedReasonsBarChartData[] {
    const colors = ['#0701C5', '#0084F4', '#66B5F8', '#6300FF', '#9D62FB']
    const includedReasonsMap:Record<string, number[]> = this.getIncludedDeclinedReasons()

    const barChartData :DeclinedReasonsBarChartData[] = []
    let i = 0
    for (const reason in includedReasonsMap) {
      barChartData.push({
        label: DECLINED_REASONS_NAMES[reason],
        data: includedReasonsMap[reason],
        backgroundColor: colors[i % colors.length]
      })
      i++
    }

    return barChartData
  }

  get getValidationDeclinedReasonsBarChartConfig (): BarChartType {
    const maxValue = this.getDeclinedReasonsCountMaxValue(this.selectedItem)

    const barChartType :BarChartType = {
      width: '34.375rem',
      height: '25rem',
      labels: this.getDeclinedReasonsBarChartLabels(),
      data: this.getDeclinedReasonsBarChartData(),
      options: {
        layout: {
          padding: {
            top: 20,
            right: 20,
            left: 20,
            bottom: 5
          }
        },
        legend: {
          position: 'top',
          labels: {
            fontColor: '#1F026D',
            fontFamily: 'Montserrat'
          }
        },
        title: {
          display: true,
          text: 'Razones de validaciones rechazadas',
          fontColor: '#1F026D',
          fontFamily: 'Montserrat',
          fontSize: 20
        },
        scales: {
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: '',
                fontColor: '#1F026D',
                fontFamily: 'Montserrat',
                fontStyle: 'Bold',
                fontSize: 16
              },
              gridLines: {
                display: false
              }
            }
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: 'Validaciones totales',
                fontColor: '#1F026D',
                fontFamily: 'Montserrat',
                fontStyle: 'Bold',
                fontSize: 16
              },
              ticks: {
                stepSize: this.calculateStepSize(maxValue),
                min: 0,
                max: maxValue
              },
              gridLines: {
                borderDash: [5, 5]
              }
            }
          ]
        }
      }
    }

    return barChartType
  }
}
