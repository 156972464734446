export default {
  Country: "País",
  Client: "Cliente",
  "Document number": "Número documento",
  "Document type": "Tipo de documento",
  "Email address": "Correo electrónico",
  "Commercial name": "Razón social",
  "First last name": "Primer apellido",
  "Second last name": "Segundo apellido",
  "Last name": "Apellidos",
  "Name": "Nombre",
  "Contributor type": "Tipo de contribuyente",
  "Representative document number": "Número de documento representante legal",
  "Representative first last name": "Primer apellido representante legal",
  "Representative second last name": "Segundo apellido representante legal",
  "Representative name": "Nombre representante legal",
  "Issued at country": "País de expedición",
  "Issued at state": "Departamento de expedición",
  "Issued at municipality": "Municipio de expedición",
  "Residence address": "Dirección",
  "Postal code": "Código postal",
  "Phone number": "Número telefónico",
  "Main activity code": "Código de actividad principal",
  "Main activity start date": "Fecha inicio act. principal",
  "Responsibility code": "Código de responsabilidad",
  "Representative document type": "Tipo documento representante legal",
}
