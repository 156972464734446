import brNationalID from "./br_national_id"
import clNationalID from "./cl_national_id"
import coForeignID from "./co_foreign_id"
import coNationalID from "./co_national_id"
import coPTP from "./co_ppt"
import coIdentityCard from "./co_identity_card"
import coPEP from "./co_pep"
import crForeignID from "./cr_foreign_id"
import crNationalID from "./cr_national_id"
import mxNationalID from "./mx_national_id"
import mxForeignID from "./mx_foreign_id"
import mxInvoice from "./mx_invoice"
import passport from "./passport"
import peNationalID from "./pe_national_id"
import paNationalID from "./pa_national_id"
import peForeignID from "./pe_foreign_id"
import veNationalID from "./ve_national_id"
import getDefaultFields from "./default_id"
import {ItemType} from "@/models/details";
import {Validation} from "@/models/validation";
import jp from 'jsonpath'
import {parseDate} from "@/models/details/details_data";

const mapDocuments: any = {
  BR: {
    ['cnh']: brNationalID,
  },
  CL: {
    ['national-id']: clNationalID,
    ['foreign-id']: clNationalID,
    ['passport']: passport,
  },
  CO: {
    ['foreign-id']: coForeignID,
    ['national-id']: coNationalID,
    ['pep']: coPEP,
    ['passport']: passport,
    ['identity-card']: coIdentityCard,
    ['ppt']: coPTP,
  },
  CR: {
    ['foreign-id']: crForeignID,
    ['national-id']: crNationalID,
  },
  VE: {
    ['national-id']: veNationalID,
  },
  MX: {
    ['national-id']: mxNationalID,
    ['foreign-id']: mxForeignID,
    ['invoice']: mxInvoice,
    ['passport']: passport,
  },
  ALL: {
    ['passport']: passport,
  },
  PE: {
    ['national-id']: peNationalID,
    ['foreign-id']: peForeignID,
  },
  PA: {
    ['national-id']: paNationalID,
  }
}

const dateRegex =/\d{4}-\d{2}-\d{2}T00:00:00Z/
const excludedLabels: Record<string, boolean> = {
  "client_id": true,
  "doc_id": true,
  "mime_type": true,
  "creation_date": true,
  "update_date": true,
}

export function getDocumentFields(
  validation: Validation,
): { type: ItemType, label: string, value: any }[] {
  if (!validation.details) {
    return getDefaultFields(validation)
  }

  const docCountry = mapDocuments[jp.value(validation, '$..details.document_details.country')]
  if (!docCountry) {
    return getDefaultFields(validation)
  }

  const docType = jp.value(validation, '$..details.document_details.document_type')
  if (docType == "rut") {
    return getFieldsWithObjectIteration(validation)
  }

  const getDocFields = docCountry[docType]
  if (!getDocFields) {
    return getDefaultFields(validation)
  }

  return getDocFields(validation)
}


function getFieldsWithObjectIteration(
  validation: Validation,
): { type: ItemType, label: string, value: any, permission?: string, hide_in_preview?: boolean }[] {
  if (!validation.details) {
    return getDefaultFields(validation)
  }

  const fields: { type: ItemType, label: string, value: any, permission?: string, hide_in_preview?: boolean }[] = []
  const documentDetails = jp.value(validation, '$..details.document_details')

  const keys = Object.keys(documentDetails)
  const objectLength = keys.length

  for (let i = 0; i < objectLength; i++) {
    const key = keys[i]
    if (typeof documentDetails[key] == "object") {
      continue
    }

    if (excludedLabels[key]) {
      continue
    }

    const labelName = key.replaceAll("_", " ")

    let value = documentDetails[key]
    if(dateRegex.test(value)) {
      value = parseDate(value, true)
    }

    fields.push({
      hide_in_preview: false,
      label: labelName[0].toUpperCase() + labelName.slice(1).toLowerCase(),
      permission: "document." + key,
      type: ItemType.ROW,
      value: value
    })
  }

  return fields
}
