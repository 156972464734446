export default {
  Client: "Cliente",
  Country: "País",
  "Document number": "Número do documento",
  "Document type": "Tipo de documento",
  "Email address": "Correo electrónico",
  "Commercial name": "Razão social",
  "First last name": "Primeiro sobrenome",
  "Second last name": "Segundo sobrenome",
  "Last name": "Sobrenome",
  "Name": "Nome",
  "Contributor type": "Tipo de contribuinte",
  "Representative document number": "Número do documento do representante legal",
  "Representative first last name": "Primeiro sobrenome do representante legal",
  "Representative second last name": "Segundo sobrenome do representante legal",
  "Representative name": "Nome do representante legal",
  "Issued at country": "País de expedição",
  "Issued at state": "Departamento de expedição",
  "Issued at municipality": "Município de expedição",
  "Residence address": "Endereço",
  "Postal code": "Código postal",
  "Phone number": "Número de telefone",
  "Main activity code": "Código da atividade principal",
  "Main activity start date": "Data de início da atividade principal",
  "Responsibility code": "Código de responsabilidade",
  "Representative document type": "Tipo de documento representativo",
}
