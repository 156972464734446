import {ItemType} from "@/models/details";
import {COUNTRY, DOC_TYPE, GENDER, parseDate} from "@/models/details/details_data";
import jp from 'jsonpath'
import {Validation} from "@/models/validation";

export default function getCONationalIDFields(validation: Validation): { type: ItemType, label: string, value: any }[] {
  return [
    {
      type: ItemType.ROW,
      label: 'Nombres',
      value: jp.value(validation, '$..details.document_details.name')
    },
    {
      type: ItemType.ROW,
      label: 'Apellidos',
      value: jp.value(validation, '$..details.document_details.last_name')
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de nacimiento',
      value: parseDate(jp.value(validation, '$..details.document_details.date_of_birth'), true)
    },
    {
      type: ItemType.ROW,
      label: 'Lugar de nacimiento',
      value: jp.value(validation, '$..details.document_details.birth_place')
    },
    {
      type: ItemType.ROW,
      label: 'Género',
      value: GENDER[jp.value(validation, '$..details.document_details.gender')]
    },
    {
      type: ItemType.ROW,
      label: 'País',
      value: COUNTRY[jp.value(validation, '$..details.document_details.country')]
    },
    {
      type: ItemType.ROW,
      label: 'Tipo de documento',
      value: DOC_TYPE[jp.value(validation, '$..details.document_details.document_type')]
    },
    {
      type: ItemType.ROW,
      label: 'Número de documento',
      value: jp.value(validation, '$..details.document_details.document_number')
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de expedición',
      value: parseDate(jp.value(validation, '$..details.document_details.issue_date'), true)
    },
    {
      type: ItemType.ROW,
      label: 'Lugar de expedición',
      value: jp.value(validation, '$..details.document_details.expedition_place')
    },
    {
      type: ItemType.ROW,
      label: 'Altura',
      value: jp.value(validation, '$..details.document_details.height')
    },
    {
      type: ItemType.ROW,
      label: 'Tipo de sangre',
      value: jp.value(validation, '$..details.document_details.rh')
    }
  ]
}
