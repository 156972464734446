import {Validation} from "@/models/validation";
import {ItemType} from "@/models/details";
import {DOC_TYPE, GENDER, parseDate} from "@/models/details/details_data";
import jp from 'jsonpath'

export default function getMXNationalIDFields(validation: Validation): { type: ItemType, label: string, value: any }[] {
  return [
    {
      type: ItemType.ROW,
      label: 'Tipo de documento',
      value: DOC_TYPE[jp.value(validation, '$..details.document_details.document_type')]
    },
    {
      type: ItemType.ROW,
      label: 'Nombres',
      value: jp.value(validation, '$..details.document_details.name')
    },
    {
      type: ItemType.ROW,
      label: 'Apellidos',
      value: jp.value(validation, '$..details.document_details.last_name')
    },
    {
      type: ItemType.ROW,
      label: 'Primer apellido',
      value: jp.value(validation, '$..details.document_details.first_last_name')
    },
    {
      type: ItemType.ROW,
      label: 'Segundo apellido',
      value: jp.value(validation, '$..details.document_details.second_last_name')
    },
    {
      type: ItemType.ROW,
      label: 'Domicilio',
      value: jp.value(validation, '$..details.document_details.residence_address')
    },
    {
      type: ItemType.ROW,
      label: 'Género',
      value: GENDER[jp.value(validation, '$..details.document_details.gender')]
    },
    {
      type: ItemType.ROW,
      label: 'Estado',
      value: jp.value(validation, '$..details.document_details.mexico_document.state')
    },
    {
      type: ItemType.ROW,
      label: 'Municipio',
      value: jp.value(validation, '$..details.document_details.mexico_document.municipality')
    },
    {
      type: ItemType.ROW,
      label: 'Localidad',
      value: jp.value(validation, '$..details.document_details.mexico_document.locality')
    },
    {
      type: ItemType.ROW,
      label: 'Sección',
      value: jp.value(validation, '$..details.document_details.mexico_document.section')
    },
    {
      type: ItemType.ROW,
      label: 'Clave electoral',
      value: jp.value(validation, '$..details.document_details.mexico_document.elector_key')
    },
    {
      type: ItemType.ROW,
      label: 'Número de documento',
      value: jp.value(validation, '$..details.document_details.document_number')
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de nacimiento',
      value: parseDate(jp.value(validation, '$..details.document_details.date_of_birth'), true)
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de expedición',
      value: parseDate(jp.value(validation, '$..details.document_details.issue_date'), false)
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de expiración',
      value: parseDate(jp.value(validation, '$..details.document_details.expiration_date'), false)
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de registro',
      value: parseDate(jp.value(validation, '$..details.document_details.registration_date'), false)
    },
  ]
}
