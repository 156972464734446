import { ItemType, Card, LayoutItem } from '..'
import { Validation, ValidationStatus } from '../../validation'
import jp from 'jsonpath'
import { COUNTRY, DETAILS_ICONS, DETAILS_ICONS_COLOR, DOC_TYPE } from '../details_data'

export const identityQuestionsDetails = (validation: Validation): Card[] => {
  const correctQuestions = jp.value(validation, '$.user_response.correct_questions') || []
  const questionnaire = jp.query(validation, '$.validation_inputs.questionnaire[*]').map(question => {
    const status = correctQuestions.includes(question.question_id) ? ValidationStatus.Success : ValidationStatus.Failure

    return {
      type: ItemType.ROW,
      value: question.question,
      icon: DETAILS_ICONS[status],
      icon_color: DETAILS_ICONS_COLOR[status]
    }
  })

  const threshold = jp.value(validation, '$.threshold')

  return [
    {
      title: 'Detalles de la validación',
      first_section: [
        {
          type: ItemType.ROW,
          label: 'Tipo de documento',
          value: DOC_TYPE[jp.value(validation, '$.validation_inputs.document_type')]
        },
        {
          type: ItemType.ROW,
          label: 'Número de documento',
          value: jp.value(validation, '$.validation_inputs.document_number')
        },
        {
          type: ItemType.ROW,
          label: 'País',
          value: COUNTRY[jp.value(validation, '$.validation_inputs.country')]
        },
        {
          type: ItemType.ROW,
          label: 'Umbral',
          value: `${threshold * 100} %`
        }
      ] as LayoutItem[],
      second_section: [
        {
          type: ItemType.ROW,
          label: 'Preguntas de seguridad'
        },
        ...questionnaire
      ] as LayoutItem[]
    }
  ]
}
