import { ItemType, Card, LayoutItem } from '..'
import { Validation } from '../../validation'
import jp from 'jsonpath'

export const voiceRecognitionDetails = (validation: Validation): Card[] => {
  let phrase = jp.value(validation, '$.validation_inputs.phrase')
  if (!phrase.startsWith('"')) {
    phrase = `"${phrase}"`
  }

  return [
    {
      title: 'Detalles de la validación',
      first_section: [
        {
          type: ItemType.ROW,
          label: 'Lenguaje',
          value: jp.value(validation, '$.validation_inputs.language')
        },
        {
          type: ItemType.ROW,
          label: 'Frase de validación',
          value: phrase
        }
      ],
      second_section: [
        {
          type: ItemType.VOICE_NOTE,
          label: 'Huella de voz',
          value: jp.value(validation, '$.validation_inputs.validation_input_files[0]')
        },
        {
          type: ItemType.VOICE_NOTE,
          label: 'Voz a validar',
          value: jp.value(validation, '$.user_response.input_files[0]')
        }
      ]
    }
  ]
}
