import {Card, ItemType, LayoutItem} from '../'
import {Validation, ValidationStatus} from '../../validation'
import {DETAILS_ICONS, DETAILS_ICONS_COLOR} from '../details_data'
import jp from 'jsonpath'
import {getDocumentFields} from "@/models/details/validators/document_details/document";

const hasSkippedStatusValidationObject = (validationObject) => {
  for (const property in validationObject) {
    if (typeof validationObject[property] !== 'object' || validationObject[property] === null) {
      return false
    }

    if (validationObject[property].result === 'skipped') {
      return true
    }
  }

  return false
}

const getDocValidationsToShow = (availableValidations, inputValidations) => {
  const validationsToShow = [] as any
  const listOfInputValidations = inputValidations[0]

  for (const validation in listOfInputValidations) {
    if (hasSkippedStatusValidationObject(listOfInputValidations[validation])) {
      continue
    }
    
    if (validation == "manual_analysis"){
      validationsToShow.unshift(availableValidations[validation])
    }
    else { 
      validationsToShow.push(availableValidations[validation])
    }
  }

  return validationsToShow
}

const getValidationStatus = (validations: string[]) => {
  if (validations.includes('invalid') || validations.includes('error')) {
    return ValidationStatus.Failure
  }

  return ValidationStatus.Success
}

const wasValidationMethodPerformed = (validations: string[]): boolean => {
  if (validations.length == 0 || validations.includes("skipped")) {
    return false
  }
  return true
}

export const documentValidationDetails = (validation: Validation): Card[] => {
  const inputValidations = jp.query(validation, '$..details.document_validations')
  const consistency = jp.query(validation, '$..details.document_validations.data_consistency[*].result')
  const governmentAnalysis = jp.query(validation, '$..details.document_validations.government_database[*].result')
  let imageAnalysis = jp.query(validation, '$..details.document_validations.image_analysis[*].result')
  let photocopyAnalysis = jp.query(validation, '$..details.document_validations.photocopy_analysis[*].result')
  let photoOfPhotoAnalysis = jp.query(validation, '$..details.document_validations.photo_of_photo[*].result')
  const manualAnalysis = jp.query(validation, '$..details.document_validations.manual_analysis[*].result')
  const mimeType = jp.value(validation, '$..details.document_details.mime_type')

  // Manual review overrides existing responses
  if (wasValidationMethodPerformed(manualAnalysis)) {
    photoOfPhotoAnalysis = jp.query(
      validation,
      "$..details.document_validations.manual_analysis[?(@.validation_type == 'photo-of-photo-validation')].result"
    )
    const colorPhotocopyAnalysis = jp.query(
      validation,
      "$..details.document_validations.manual_analysis[?(@.validation_type == 'photocopy-validation-color')].result"
    )
    const blackWhitePhotocopyAnalysis = jp.query(
      validation,
      "$..details.document_validations.manual_analysis[?(@.validation_type == 'photocopy-validation')].result"
    )
    photocopyAnalysis = [...colorPhotocopyAnalysis, ...blackWhitePhotocopyAnalysis]

    const fakePictureAnalysis = jp.query(
      validation,
      "$..details.document_validations.manual_analysis[?(@.validation_type == 'fake-picture-validation')].result"
    )
    const fakeTextAnalysis = jp.query(
      validation,
      "$..details.document_validations.manual_analysis[?(@.validation_type == 'fake-text-validation')].result"
    )
    imageAnalysis = [...fakePictureAnalysis, ...fakeTextAnalysis]
  }

  const consistencyStatus = getValidationStatus(consistency)

  const imageAnalysisStatus = getValidationStatus(imageAnalysis)

  const governmentAnalysisStatus = getValidationStatus(governmentAnalysis)
  const photocopyAnalysisStatus = getValidationStatus(photocopyAnalysis)
  const photoOfPhotoAnalysisStatus = getValidationStatus(photoOfPhotoAnalysis)
  const manualAnalysisStatus = getValidationStatus(manualAnalysis)

  const availableValidationsPassport: Record<string, LayoutItem> = {
    data_consistency: {
      type: ItemType.ROW,
      label: 'Consistencia de datos',
      icon: DETAILS_ICONS[consistencyStatus],
      icon_color: DETAILS_ICONS_COLOR[consistencyStatus]
    },
    manual_analysis: {
      type: ItemType.ROW,
      label: 'Revisión manual',
      icon: DETAILS_ICONS[manualAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[manualAnalysisStatus]
    }
  }

  const availableValidationsCOL: Record<string, LayoutItem> = {
    data_consistency: {
      type: ItemType.ROW,
      label: 'Consistencia de datos',
      icon: DETAILS_ICONS[consistencyStatus],
      icon_color: DETAILS_ICONS_COLOR[consistencyStatus]
    },
    image_analysis: {
      type: ItemType.ROW,
      label: 'Análisis de imagen',
      icon: DETAILS_ICONS[imageAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[imageAnalysisStatus]
    },
    government_database: {
      type: ItemType.ROW,
      label: 'Bases de datos oficiales',
      icon: DETAILS_ICONS[governmentAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[governmentAnalysisStatus]
    },
    photocopy_analysis: {
      type: ItemType.ROW,
      label: 'Análisis de fotocopia',
      icon: DETAILS_ICONS[photocopyAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[photocopyAnalysisStatus]
    },
    photo_of_photo: {
      type: ItemType.ROW,
      label: 'Análisis de foto de foto',
      icon: DETAILS_ICONS[photoOfPhotoAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[photoOfPhotoAnalysisStatus]
    },
    manual_analysis: {
      type: ItemType.ROW,
      label: 'Revisión manual',
      icon: DETAILS_ICONS[manualAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[manualAnalysisStatus]
    }
  }

  const availableValidationsMX: Record<string, LayoutItem> = {
    data_consistency: {
      type: ItemType.ROW,
      label: 'Consistencia de datos',
      icon: DETAILS_ICONS[consistencyStatus],
      icon_color: DETAILS_ICONS_COLOR[consistencyStatus]
    },
    government_database: {
      type: ItemType.ROW,
      label: 'Bases de datos oficiales',
      icon: DETAILS_ICONS[governmentAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[governmentAnalysisStatus]
    },
    manual_analysis: {
      type: ItemType.ROW,
      label: 'Revisión manual',
      icon: DETAILS_ICONS[manualAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[manualAnalysisStatus]
    },
    photo_of_photo: {
      type: ItemType.ROW,
      label: 'Análisis de foto de foto',
      icon: DETAILS_ICONS[photoOfPhotoAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[photoOfPhotoAnalysisStatus]
    }
  }

  const availableValidationsCL: Record<string, LayoutItem> = {
    data_consistency: {
      type: ItemType.ROW,
      label: 'Consistencia de datos',
      icon: DETAILS_ICONS[consistencyStatus],
      icon_color: DETAILS_ICONS_COLOR[consistencyStatus]
    },
    government_database: {
      type: ItemType.ROW,
      label: 'Bases de datos oficiales',
      icon: DETAILS_ICONS[governmentAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[governmentAnalysisStatus]
    },
    manual_analysis: {
      type: ItemType.ROW,
      label: 'Revisión manual',
      icon: DETAILS_ICONS[manualAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[manualAnalysisStatus]
    }
  }

  const availableValidationsBR: Record<string, LayoutItem> = {
    data_consistency: {
      type: ItemType.ROW,
      label: 'Consistencia de datos',
      icon: DETAILS_ICONS[consistencyStatus],
      icon_color: DETAILS_ICONS_COLOR[consistencyStatus]
    },
    government_database: {
      type: ItemType.ROW,
      label: 'Bases de datos oficiales',
      icon: DETAILS_ICONS[governmentAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[governmentAnalysisStatus]
    },
    manual_analysis: {
      type: ItemType.ROW,
      label: 'Revisión manual',
      icon: DETAILS_ICONS[manualAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[manualAnalysisStatus]
    }
  }

  const availableValidationsPE: Record<string, LayoutItem> = {
    data_consistency: {
      type: ItemType.ROW,
      label: 'Consistencia de datos',
      icon: DETAILS_ICONS[consistencyStatus],
      icon_color: DETAILS_ICONS_COLOR[consistencyStatus]
    },
    government_database: {
      type: ItemType.ROW,
      label: 'Bases de datos oficiales',
      icon: DETAILS_ICONS[governmentAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[governmentAnalysisStatus]
    },
    manual_analysis: {
      type: ItemType.ROW,
      label: 'Revisión manual',
      icon: DETAILS_ICONS[manualAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[manualAnalysisStatus]
    },
    photo_of_photo: {
      type: ItemType.ROW,
      label: 'Análisis de foto de foto',
      icon: DETAILS_ICONS[photoOfPhotoAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[photoOfPhotoAnalysisStatus]
    }
  }

  const availableValidationsPA: Record<string, LayoutItem> = {
    data_consistency: {
      type: ItemType.ROW,
      label: 'Consistencia de datos',
      icon: DETAILS_ICONS[consistencyStatus],
      icon_color: DETAILS_ICONS_COLOR[consistencyStatus]
    },
    manual_analysis: {
      type: ItemType.ROW,
      label: 'Revisión manual',
      icon: DETAILS_ICONS[manualAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[manualAnalysisStatus]
    }
  }

  const availableValidationsVE: Record<string, LayoutItem> = {
    data_consistency: {
      type: ItemType.ROW,
      label: 'Consistencia de datos',
      icon: DETAILS_ICONS[consistencyStatus],
      icon_color: DETAILS_ICONS_COLOR[consistencyStatus]
    },
    manual_analysis: {
      type: ItemType.ROW,
      label: 'Revisión manual',
      icon: DETAILS_ICONS[manualAnalysisStatus],
      icon_color: DETAILS_ICONS_COLOR[manualAnalysisStatus]
    }
  }

  const cardsPassport = [
    {
      title: 'Validaciones realizadas',
      subtitle: '',
      first_section: getDocValidationsToShow(availableValidationsPassport, inputValidations)
    },
    {
      title: 'Detalles de la validación',
      subtitle: 'Información extraída del documento',
      first_section: getDocumentFields(validation),
      second_section: [
        {
          type: ItemType.IMAGE,
          label: 'ID',
          value: jp.value(validation, '$..user_response.input_files[0]')
        }
      ]
    }
  ]

  let documentSecondSection = [
    {
      type: ItemType.IMAGE,
      label: 'ID frontal',
      value: jp.value(validation, '$..user_response.input_files[0]')
    },
    {
      type: ItemType.IMAGE,
      label: 'ID posterior',
      value: jp.value(validation, '$..user_response.input_files[1]')
    }
  ]

  // TODO: Change this to use document fields instead attachment validations
  const attachmentTypes = jp.query(validation, '$..attachment_validations[*].validation_type')

  const numberOfOCRValidations = attachmentTypes.filter(validationType => validationType === "ocr-validation").length;
  if (numberOfOCRValidations === 1) {
    documentSecondSection = [
      {
        type: ItemType.IMAGE,
        label: 'ID frontal',
        value: jp.value(validation, '$..user_response.input_files[0]')
      }]
  }

  if (mimeType == 'application/pdf') {
    documentSecondSection =  [
      {
        type: ItemType.PDF,
        label: 'Documento PDF',
        value: jp.value(validation, '$..user_response.input_files[0]')
      }
    ]
  }

  const cardsCOL = [
    {
      title: 'Validaciones realizadas',
      subtitle: '',
      first_section: getDocValidationsToShow(availableValidationsCOL, inputValidations)
    },
    {
      title: 'Detalles de la validación',
      subtitle: 'Información extraída del documento',
      first_section: getDocumentFields(validation),
      second_section: documentSecondSection
    }
  ]

  const cardsMX = [
    {
      title: 'Validaciones realizadas',
      subtitle: '',
      first_section: getDocValidationsToShow(availableValidationsMX, inputValidations)
    },
    {
      title: 'Detalles de la validación',
      subtitle: 'Información extraída del documento',
      validation_status: validation.validation_status,
      first_section: getDocumentFields(validation),
      second_section: documentSecondSection
    }
  ]

  const cardsCL = [
    {
      title: 'Validaciones realizadas',
      subtitle: '',
      first_section: getDocValidationsToShow(availableValidationsCL, inputValidations)
    },
    {
      title: 'Detalles de la validación',
      subtitle: 'Información extraída del documento',
      validation_status: validation.validation_status,
      first_section: getDocumentFields(validation),
      second_section: documentSecondSection
    }
  ]

  const cardsBR = [
    {
      title: 'Validaciones realizadas',
      subtitle: '',
      first_section: getDocValidationsToShow(availableValidationsBR, inputValidations)
    },
    {
      title: 'Detalles de la validación',
      subtitle: 'Información extraída del documento',
      validation_status: validation.validation_status,
      first_section: getDocumentFields(validation),
      second_section: documentSecondSection
    }
  ]

  const cardsPE = [
    {
      title: 'Validaciones realizadas',
      subtitle: '',
      first_section: getDocValidationsToShow(availableValidationsPE, inputValidations)
    },
    {
      title: 'Detalles de la validación',
      subtitle: 'Información extraída del documento',
      validation_status: validation.validation_status,
      first_section: getDocumentFields(validation),
      second_section: documentSecondSection
    }
  ]

  const cardsPA = [
    {
      title: 'Validaciones realizadas',
      subtitle: '',
      first_section: getDocValidationsToShow(availableValidationsPA, inputValidations)
    },
    {
      title: 'Detalles de la validación',
      subtitle: 'Información extraída del documento',
      validation_status: validation.validation_status,
      first_section: getDocumentFields(validation),
      second_section: documentSecondSection
    }
  ]

  const cardsVE = [
    {
      title: 'Validaciones realizadas',
      subtitle: '',
      first_section: getDocValidationsToShow(availableValidationsVE, inputValidations)
    },
    {
      title: 'Detalles de la validación',
      subtitle: 'Información extraída del documento',
      validation_status: validation.validation_status,
      first_section: getDocumentFields(validation),
      second_section: documentSecondSection
    }
  ]

  const docType = jp.value(validation, '$..details.document_details.document_type')

  if (docType === 'passport') {
    return cardsPassport
  }

  const CARDS_BY_COUNTRY: { [key in string]: Card[] } = {
    CO: cardsCOL,
    MX: cardsMX,
    CL: cardsCL,
    BR: cardsBR,
    PE: cardsPE,
    PA: cardsPA,
    VE: cardsVE
  }

  const country = jp.value(validation, '$..details.document_details.country')
  if (country === undefined) {
    return []
  }

  return CARDS_BY_COUNTRY[country] || ""
}
