import { ValidationTypes } from '@/models/validation'

export const ValidationsCountersKeys = { ...ValidationTypes }
export type ValidationsCountersKeys = ValidationTypes

export interface Counters {
  created_count: number;
  failed_count: number;
  failed_declined_count: number;
  failed_expired_count: number;
  failed_system_error_count: number;
  succeeded_count: number;
}

export type Validations = {
  [key in ValidationsCountersKeys]: Counters
}

export interface ValidationsCounters {
  period: string;
  update_date: Date;
  validations: Validations;
}
export interface CountersState {
  position: number;
  countersHistory: ValidationsCounters[],
  countersLoading: boolean,
  selectedValidator: string,
  error: Error | null
}

export const defaultState: CountersState = {
  position: 0,
  countersHistory: [],
  countersLoading: false,
  selectedValidator: ValidationTypes.PhoneVerification,
  error: null
}

export default {
  ...defaultState
}
