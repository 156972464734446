import { getCookieStorageDomain } from '@/config/constants'
import { CookieStorage } from 'amazon-cognito-identity-js'

export const AWS_CONFIG = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: '',

    // REQUIRED - Amazon Cognito Region
    region: 'us-east-1',

    // OPTIONAL - Amazon Cognito Federated Identity Pool Region
    // Required only if it's different from Amazon Cognito Region
    identityPoolRegion: 'us-east-1',

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: '',

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '',

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    cookieStorage: new CookieStorage({
      domain: getCookieStorageDomain(),
      secure: true,
      expires: 1,
      sameSite: 'none'
    })
  }
}
