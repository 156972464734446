import { ValidationsCounters, CountersState, defaultState } from './state'

export const setPosition = function (state: CountersState, position: number): void {
  state.position = position
}

export const setCountersHistory = function (state: CountersState, counters: ValidationsCounters[]): void {
  state.countersHistory = counters
}

export const setCountersLoading = function (state: CountersState, loading: boolean): void {
  state.countersLoading = loading
}

export const setError = function (state: CountersState, error: Error): void {
  state.error = error
}

export const setSelectedValidator = function (state: CountersState, selectedValidator: string): void{
  state.selectedValidator = selectedValidator
}

export const reset = function (state: CountersState): void {
  const keys = Object.keys(defaultState)
  for (const key of keys) {
    if (key === 'countersLoading') {
      continue
    }

    state[key] = defaultState[key]
  }
}
