import { DeclinedReason } from '@/models/declined_reason'
import { ValidationTypes, ValidationStatus } from '@/models/validation'

export enum Percentiles {
  p1 = '1.0',
  p5 = '5.0',
  p25 = '25.0',
  p50 = '50.0',
  p75 = '75.0',
  p95 = '95.0',
  p99 = '99.0'
}

export interface ValidationMetric {
  interaction_time: number[]
}

export type StatusTimes = Record<ValidationStatus, ValidationMetric>

export type ValidationsTimes = Record<ValidationTypes, StatusTimes>

export interface DeclinedReasonCounters {
  counts:number
}

export type ValidationsDeclinedReasons = {
  [key in ValidationTypes]: Record<DeclinedReason, DeclinedReasonCounters>
}

export interface ValidationsDeclinedReasonMetrics {
  period: string;
  metrics: ValidationsDeclinedReasons;
}

export interface MetricsState {
  validationsTimes: ValidationsTimes | null,
  validationTimesLoading: boolean,
  validationDeclinedReasonsHistory: ValidationsDeclinedReasonMetrics[],
  validationDeclinedReasonsLoading: boolean,
  error: Error | null,
  validator: ValidationTypes
}

export const defaultState: MetricsState = {
  validationsTimes: null,
  validationTimesLoading: false,
  validationDeclinedReasonsHistory: [],
  validationDeclinedReasonsLoading: false,
  error: null,
  validator: ValidationTypes.DocumentValidation
}

export default {
  ...defaultState
}
