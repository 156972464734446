
import Vue from "vue"
import ValidationDetail from '@/components/Validations/ValidationDetail.vue'
import { mapGetters, mapActions, mapState } from "vuex"
import { getValidationLayoutSpecification } from '@/models/details'
import Snackbar from '@bit/truora.pneuma.snackbar'

export default Vue.extend({
  components: {
    ValidationDetail,
    Snackbar
  },
  data(){
    return {
      notification: {
        show: false,
        title: '',
        message: '',
        type: ''
      }
    }
  },
  methods: {
    ...mapActions("validations", ["fetchCurrentValidation"]),
    goToHistoryView () {
      this.$router.push('/history')
    }
  },
  computed: {
    ...mapGetters("validations", ["getCurrentValidation", "getCurrentValidationLoading"]),
    ...mapState("validations",["error"]),
    cards () {
      if (
        this.getCurrentValidation === undefined ||
        this.getCurrentValidation === null
      ) {
        return []
      }

      return getValidationLayoutSpecification(this.getCurrentValidation).cards
    },
  },
  watch: {
    error (value: Error): void {
      if (value) {
        this.notification = {
          show: true,
          title: value.message,
          message: '',
          type: 'error'
        }
      }
    }
  },
  beforeMount () {
    const validationID = this.$router.currentRoute.params.validation_id
    this.fetchCurrentValidation({ validationID })
  }
})
