import en from "./langs/en"
import es from "./langs/es"
import pt from "./langs/pt"

const messages = {
  pt: pt,
  en: en,
  es: es,
}

export default messages
