import Vue from 'vue'
import Vuex from 'vuex'
import counters from '@/store/modules/counters'
import metrics from '@/store/modules/metrics'
import validations from '@/store/modules/validations'
import zapsign from '@/store/modules/zapsign'
import auth from '@/store/modules/auth'
import { runSaga, stdChannel } from 'redux-saga'
import sagas from '@/sagas/index'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    counters,
    metrics,
    validations,
    zapsign
  }
})

export const dispatch = (output: { type: string; payload: Record<string, unknown> }): void => store.commit(output.type, output.payload)
export const getState = (): unknown => store.state

sagas.forEach(saga => {
  const channel = stdChannel()
  store.subscribeAction(channel.put)

  runSaga({
    channel,
    dispatch,
    getState
  },
    saga
  )
})

export default store
