import { takeLatest, select, call, put, delay, StrictEffect } from "redux-saga/effects";
import { AuthRequest } from "@/services/truora_api";
import {
  InvalidStatusCodeError,
  UndefinedResponseError,
  GetValidationError,
  GetValidationsError,
  StoreFeedbackError,
  NextValidationsPageError,
  PreviousValidationsPageError,
  DownloadValidationsHistoryError,
} from "./errors";
import log from "@/services/logger";
import { BASE_API, CHECKS_BASE_API } from "@/config/constants";
import { iso8601DateToLocalRFC3339 } from "@/helpers/utils";
import { AxiosRequestConfig, type AxiosResponse, AxiosError } from "axios";
import { urlParamsFromObject } from "../helpers/utils";

const DELAY = 3 * 1000;
const MAX_RETRIES = 300; // amount of 3 seconds intervals in 15 minutes

export interface ActionPayload<T> {
  type: string;
  payload: T;
}

export interface FetchValidationRequest {
  validationID: string;
  accountID?: string;
}

export interface CreateHistoryReportResponse {
  history_report_id: string;
  start_date: string;
  end_date: string;
  creation_date: string;
}

export interface GetHistoryReportResponse {
  message: string;
  progress: number;
}
export function* handleFetchCurrentValidation({
  payload,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
ActionPayload<FetchValidationRequest>): Generator<StrictEffect, void, any> {
  try {
    yield put({ type: "validations/setCurrentValidationLoading", payload: true });

    const { validationID } = payload;

    const response = yield call(AuthRequest.get, `/v1/validations/${validationID}`, {
      baseURL: BASE_API,
      params: {
        show_details: true,
      },
    });

    if (response === undefined) {
      const err = new UndefinedResponseError();
      log.error("getting_validation_failed:", err);
      yield put({ type: "validations/setError", payload: new GetValidationError() });
      return;
    }

    if (response.status !== 200) {
      const err = new InvalidStatusCodeError(response.status);
      log.error("getting_validation_failed:", err);
      yield put({ type: "validations/setError", payload: new GetValidationError() });
      return;
    }

    const validation = response.data;
    validation.creation_date = new Date(validation.creation_date);

    yield put({ type: "validations/setCurrentValidation", payload: validation });
  } catch (e) {
    const errResponse = getErrorResponse(e);
    log.error("get_validation_error", errResponse);
    yield put({ type: "validations/setError", payload: new GetValidationError() });
  } finally {
    yield put({ type: "validations/setCurrentValidationLoading", payload: false });
  }
}

function getValidationsPath(validationsPath: string, index: number, pages: string[]): string {
  if (index === 0 && pages[index] === undefined) {
    return validationsPath;
  }

  if (index === 0 && pages[index] !== validationsPath) {
    return validationsPath;
  }

  return pages[index];
}

function getValidationsConfigParams(index: number, params?: AxiosRequestConfig["params"]) {
  const config = {
    baseURL: BASE_API,
    params: undefined,
  };

  if (params === undefined || params.payload === undefined || index > 0) {
    return config;
  }

  config.params = params.payload;

  return config;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* handleStoreValidationFeedback(
  params: AxiosRequestConfig["params"]
): Generator<StrictEffect, void, any> {
  try {
    yield put({ type: "validations/setValidationFeedbackLoading", payload: true });

    const { payload } = params;

    const data = urlParamsFromObject(payload);

    const response = yield call(AuthRequest.post, "v1/feedback", {
      baseURL: BASE_API,
      headers: { "content-type": "application/x-www-form-urlencoded" },
      data,
    });

    if (response === undefined) {
      const err = new UndefinedResponseError();
      log.error("storing_validation_feedback_failed:", err);
      yield put({ type: "validations/setError", payload: new StoreFeedbackError() });
      return;
    }

    if (response.status !== 201) {
      const err = new InvalidStatusCodeError(response.status);
      log.error("storing_validation_feedback_failed", err);
      yield put({ type: "validations/setError", payload: new StoreFeedbackError() });
      return;
    }
    yield put({ type: "validations/setValidationFeedbackSuccess", payload: true });
    yield put({
      type: "validations/setValidationFeedbackReportEnrollmentID",
      payload: response.data.report_enrollment_id,
    });
  } catch (e) {
    const errResponse = getErrorResponse(e);
    log.error("storing_validation_feedback_failed", errResponse);
    yield put({ type: "validations/setError", payload: new StoreFeedbackError() });
  } finally {
    yield put({ type: "validations/setValidationFeedbackLoading", payload: false });
  }
}

const areEqualObjectKeys = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
};

const shallowEqual = (object1, object2) => {
  if (object1 === undefined && object2 === undefined) {
    return true;
  }

  if (object1 === undefined || object2 === undefined) {
    return false;
  }

  return areEqualObjectKeys;
};

const setPages = (pages, index, validations) => {
  if (pages) {
    pages[index] = validations.self;
    pages[index + 1] = validations.next;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* handleFetchValidations(
  params?: AxiosRequestConfig["params"]
): Generator<StrictEffect, void, any> {
  let validationsPath = setValidationsPath(params);

  try {
    yield put({ type: "validations/setValidationsLoading", payload: true });

    let state = yield select((state) => state.validations.validationsPagination);

    if (!shallowEqual(state.searchParams, params.payload)) {
      yield put({ type: "validations/resetSearch", payload: params.payload });
      state = yield select((state) => state.validations.validationsPagination);
    }

    const index = state.currentPage;
    const pages = state.pages;
    const config = getValidationsConfigParams(index, params);
    validationsPath = getValidationsPath(validationsPath, index, pages);

    const response = yield call(AuthRequest.get, validationsPath, config);
    if (response === undefined) {
      const err = new UndefinedResponseError();
      log.error("getting_validations_failed:", err);
      yield put({ type: "validations/setError", payload: new GetValidationsError() });

      return;
    }

    if (response.status !== 200) {
      const err = new InvalidStatusCodeError(response.status);
      log.error("getting_validation_failed:", err);
      yield put({ type: "validations/setError", payload: new GetValidationsError() });

      return;
    }

    const validations = response.data;
    yield put({ type: "validations/updateValidations", payload: validations });
    yield put({ type: "validations/setLoadedPage", payload: index + 1 });

    setPages(pages, index, validations);

    yield put({ type: "validations/updatePages", payload: pages });
  } catch (e) {
    const errResponse = getErrorResponse(e);
    if (errResponse.http_code === 404) {
      yield put({
        type: "validations/updateValidations",
        payload: {
          history: [],
        },
      });
      return;
    }

    log.error("get_validations_error", errResponse);
    yield put({ type: "validations/setError", payload: new GetValidationsError() });
  } finally {
    yield put({ type: "validations/setValidationsLoading", payload: false });
  }
}

function setValidationsPath(params?: AxiosRequestConfig["params"]): string {
  let validationsPath = "v1/validations";
  const hasPayload = params && params.payload;

  if (hasPayload && Object.prototype.hasOwnProperty.call(params.payload, "account_id")) {
    const { account_id } = params.payload;
    validationsPath = `v1/validations?begins_with=${account_id}`;
    delete params.payload.account_id;
  }

  if (hasPayload && Object.prototype.hasOwnProperty.call(params.payload, "begins_with")) {
    const { begins_with } = params.payload;
    validationsPath = `v1/validations?begins_with=${begins_with}&set_client_id=true`;
    delete params.payload.begins_with;
  }

  return validationsPath;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* handleFetchNextValidationsPage(
  params?: AxiosRequestConfig["params"]
): Generator<StrictEffect, void, any> {
  try {
    let index = yield select((state) => state.validations.validationsPagination.currentPage);
    const pages = yield select((state) => state.validations.validationsPagination.pages);
    index += 1;

    if (index > pages.length || pages[index] === "") {
      return;
    }

    yield put({ type: "validations/updatePageIndex", payload: index });
    yield call(handleFetchValidations, params);
  } catch (e) {
    const errResponse = getErrorResponse(e);
    log.error("get_next_validations_page_error", errResponse);
    yield put({ type: "validations/setError", payload: new NextValidationsPageError() });
  } finally {
    yield put({ type: "validations/setValidationsLoading", payload: false });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* handleFetchPreviousValidationsPage(
  params?: AxiosRequestConfig["params"]
): Generator<StrictEffect, void, any> {
  try {
    let index = yield select((state) => state.validations.validationsPagination.currentPage);
    if (index <= 0) {
      return;
    }

    const pages = yield select((state) => state.validations.validationsPagination.pages);

    index -= 1;

    yield put({ type: "validations/updatePageIndex", payload: index });
    yield call(handleFetchValidations, params);
  } catch (e) {
    const errResponse = getErrorResponse(e);
    log.error("get_previous_validations_page_error", errResponse);
    yield put({ type: "validations/setError", payload: new PreviousValidationsPageError() });
  } finally {
    yield put({ type: "validations/setValidationsLoading", payload: false });
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function* handleFetchValidationByID(validationID): Generator<StrictEffect, void, any> {
  const validationsPath = `v1/validations/${validationID.payload}`;

  try {
    yield put({ type: "validations/setValidationsLoading", payload: true });

    const response = yield call(AuthRequest.get, validationsPath, {
      baseURL: BASE_API,
    });
    if (response === undefined) {
      const err = new UndefinedResponseError();
      log.error("getting_validations_failed:", err);
      yield put({ type: "validations/setError", payload: new GetValidationError() });
      return;
    }

    if (response.status !== 200) {
      const err = new InvalidStatusCodeError(response.status);
      log.error("getting_validation_failed:", err);
      yield put({ type: "validations/setError", payload: new GetValidationError() });
      return;
    }

    // Imitating the response object of allValidations to maintain consistency of the mutation
    const validation = { history: [response.data] };
    yield put({ type: "validations/updateValidations", payload: validation });
    yield put({ type: "validations/setLoadedPage", payload: 1 });
    yield put({ type: "validations/updatePages", payload: [] });
  } catch (e) {
    const errResponse = getErrorResponse(e);
    if (errResponse.http_code === 404) {
      yield put({
        type: "validations/updateValidations",
        payload: {
          history: [],
        },
      });
      return;
    }

    log.error("get_validations_error", errResponse);
    yield put({ type: "validations/setError", payload: new GetValidationError() });
  } finally {
    yield put({ type: "validations/setValidationsLoading", payload: false });
  }
}

export function* handleDownloadValidationsHistory(
  params?: AxiosRequestConfig["params"]
): Generator<StrictEffect, void, any> {
  try {
    yield put({ type: "validations/setDownloadInProgress", payload: true });
    yield put({ type: "validations/setDownloadProgress", payload: 0 });
    yield put({ type: "validations/setDownloadRemainingSeconds", payload: 0 });

    const historyReportInput = {
      //Date Picker returns a string in the format iso8601 (YYYY-MM-DD)
      start_date: formatDateInput(params.payload.start_date) || "",
      end_date: formatDateInput(params.payload.end_date) || "",
      report_type: "validations",
      validation_type: params.payload.validation_type,
      validation_status: params.payload.validation_status,
      language: params.payload.language,
    };

    const res = (yield call(AuthRequest.postForm, "v1/history-reports", {
      baseURL: CHECKS_BASE_API,
      data: historyReportInput,
    })) as AxiosResponse<CreateHistoryReportResponse>;

    const id = res.data.history_report_id;
    const historyReportPath = `/v1/history-reports/${id}`;

    const startTime = Date.now();
    let lastProgress = 0;
    for (let counter = 0; counter < MAX_RETRIES; counter++) {
      const res = (yield call(AuthRequest.get, historyReportPath, {
        baseURL: CHECKS_BASE_API,
      })) as AxiosResponse<unknown>;
      if (res.status === 202) {
        const responseData = res.data as GetHistoryReportResponse;
        if (responseData.progress !== lastProgress) {
          yield put({
            type: "validations/setDownloadProgress",
            payload: responseData.progress,
          });
          yield put({
            type: "validations/setDownloadRemainingSeconds",
            payload: getRemainingSeconds(startTime, responseData.progress),
          });
          lastProgress = responseData.progress;
        }
        // generating report
        yield delay(DELAY);
        continue;
      }

      // download report
      const url = window.URL.createObjectURL(new Blob([res.data as string]));

      const a = document.createElement("a");
      a.style.display = "none";
      a.download = `${id}.csv`;
      a.href = url;

      document.body.append(a);
      a.click();
      a.remove();

      yield put({ type: "validations/setDownloadInProgress", payload: false });
      yield put({ type: "validations/setDownloadProgress", payload: 0 });
      yield put({ type: "validations/setDownloadRemainingSeconds", payload: 0 });
      return;
    }

    yield put({ type: "validations/setDownloadTimeout", payload: true });
    throw new Error(
      "El proceso tomó demasiado tiempo. Intenta con un intervalo de tiempo más pequeño."
    );
  } catch (e) {
    const errorResponse = getErrorResponse(e as AxiosError);
    yield put({
      type: "validations/setError",
      payload: new DownloadValidationsHistoryError(errorResponse),
    });
    yield put({ type: "validations/setDownloadInProgress", payload: false });
    yield put({ type: "validations/setDownloadProgress", payload: 0 });
    yield put({ type: "validations/setDownloadRemainingSeconds", payload: 0 });
  }
}

function formatDateInput(date: string) {
  const dateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}/;
  const dates = date.match(dateRegex);
  return dates && dates[0];
}

function getRemainingSeconds(startTime: number, progress: number) {
  const elapsedTime = Date.now() - startTime;
  const progressPercentage = progress / 100;
  if (progressPercentage === 0) {
    return 0;
  }
  const remainingTime = elapsedTime / progressPercentage - elapsedTime;
  return Math.round(remainingTime / 1000);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getErrorResponse(e: any) {
  return e.response && e.response.data ? e.response.data : e;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* validation() {
  yield takeLatest("validations/fetchCurrentValidation", handleFetchCurrentValidation);
  yield takeLatest("validations/fetchAllValidations", handleFetchValidations);
  yield takeLatest("validations/fetchNextValidationsPage", handleFetchNextValidationsPage);
  yield takeLatest("validations/fetchPreviousValidationsPage", handleFetchPreviousValidationsPage);
  yield takeLatest("validations/fetchValidationByID", handleFetchValidationByID);
  yield takeLatest("validations/storeValidationFeedback", handleStoreValidationFeedback);
  yield takeLatest("validations/fetchValidationsHistory", handleDownloadValidationsHistory);
}
