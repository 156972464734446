import { CognitoUser } from "amazon-cognito-identity-js";

export enum PlanType {
  PayAsYouGoPlanType = "pay_as_you_go",
  FreeTrialPlanType = "free_trial",
  SelfServePlanType = "self_serve",
  PaidSubscription = "paid_subscription",
  EmptyPlanType = "",
}

export interface Tenant {
  client_id: string;
  user_pool_id: string;
  identity_pool_id: string;
  company_name: string;
  tenant_id: string;
}

export interface AuthState {
  tenant: Tenant | null;
  user: CognitoUser | null;
  email: string;
  password: string;
  status: string;
  error: Error | null;
  account: string;
  username: string;
}

export const defaultState: AuthState = {
  tenant: null,
  user: null,
  email: "",
  password: "",
  status: "",
  error: null,
  account: "",
  username: "",
};

export default {
  ...defaultState,
};
