
import { Vue, Component, Prop } from 'vue-property-decorator'
import { TableItem } from '../../models/tableItem'

@Component
export default class Table extends Vue {
  @Prop({ required: true }) month!: string;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) items!: TableItem[];
  @Prop({ required: true }) headers!: string[];
  @Prop() filled!: boolean;
  @Prop() height!: string;

  private wrapperClass = {
    width: '35rem',
    'max-width': '35rem',
    'box-shadow': '0 0.125rem 1rem 0 rgba(105, 105, 105, 0.15)',
    'border-radius': '0.938rem',
    'background-color': ''
  };

  private tableClass = {
    width: '100%',
    color: '#1f026d !important',
    'border-radius': '0rem 0.938rem 0.938rem 0 !important',
    'background-color': ''
  };

  mounted () {
    if (this.filled) {
      this.wrapperClass = {
        width: '35rem',
        'max-width': '35rem',
        'box-shadow': '0 0.125rem 1rem 0 rgba(105, 105, 105, 0.15)',
        'border-radius': '0.938rem',
        'background-color': '#eef0fd'
      }

      this.tableClass = {
        width: '100%',
        color: '#1f026d !important',
        'border-radius': '0rem 0.938rem 0.938rem 0 !important',
        'background-color': '#eef0fd'
      }
    }
  }

  formatValue (value: number) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
}
