import { Validation } from "@/models/validation";

export interface ValidationsPagination {
  pages: string[];
  currentPage: number;
  searchParams: Record<string, string>;
}

export interface Validations {
  history: Validation[];
  self: string;
  next: string;
}
export interface ValidationState {
  currentValidation: Validation | null;
  currentValidationLoading: boolean;
  error: Error | null;
  validationsPagination: ValidationsPagination;
  loadedPage: number;
  validations: Validations;
  validationsLoading: boolean;
  validationFeedbackLoading: boolean;
  validationFeedbackSuccess: boolean;
  itemID: string;
  params?: Record<string, string>;
  reportEnrollmentID?: string;
  downloadInProgress: boolean;
  downloadTimeout: boolean;
  downloadProgress: number;
  downloadRemainingSeconds: number;
}

export const defaultState: ValidationState = {
  currentValidation: null,
  currentValidationLoading: false,
  error: null,
  itemID: "",
  params: {},
  validationsPagination: {
    pages: [],
    currentPage: 0,
    searchParams: {},
  },
  loadedPage: 0,
  validations: {
    history: [],
    self: "",
    next: "",
  },
  validationsLoading: false,
  validationFeedbackLoading: false,
  validationFeedbackSuccess: false,
  downloadInProgress: false,
  downloadTimeout: false,
  downloadProgress: 0,
  downloadRemainingSeconds: 0,
};

export default {
  ...defaultState,
};
