import Vue from 'vue'
import Router, { RouteConfig } from 'vue-router'
import DashboardLayout from '@/layouts/DashboardLayout.vue'
import ValidationHistory from '@/views/ValidationHistory.vue'
import ValidationMetrics from '@/views/ValidationMetrics.vue'
import ValidationDetails from '@/views/ValidationDetails.vue'

Vue.use(Router)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: DashboardLayout,
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/logout',
        name: 'Logout',
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/metrics',
        name: 'ValidationMetrics',
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: ValidationMetrics,
        meta: {
          title: "Metrics",
          requiresAuth: true
        }
      },
      {
        path: '/history',
        name: 'ValidationHistory',
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: ValidationHistory,
        meta: {
          title: "Validations history",
          requiresAuth: true
        }
      },
      {
        path: '/history/:validation_id',
        name: 'ValidationDetails',
        // route level code-splitting
        // this generates a separate chunk (dashboard.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: ValidationDetails,
        meta: {
          title: "Validation details",
          requiresAuth: true
        }
      },
      {
        path: '*',
        redirect: '/history'
      }
    ]
  }
]

export default routes

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
