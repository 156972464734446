export const getCookieAuthEnabledFromLocalStorage = function (): boolean {
  const cookiesAuthEnabledLocalStorageRaw: string =
    localStorage.getItem("cookieAuthEnabled") || "false";

  return cookiesAuthEnabledLocalStorageRaw === "true";
};

export const isCookiesAuthEnabled = function (): boolean {
  const cookiesAuthenticationEnabledEnvVar =
    process.env.VUE_APP_COOKIES_AUTHENTICATION_ENABLED === "true";

  return getCookieAuthEnabledFromLocalStorage() || cookiesAuthenticationEnabledEnvVar;
};
