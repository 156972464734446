import { Card } from '.'
import { Validation, ValidationStatus, ValidationTypes } from '../validation'
import { emailDetails } from './validators/email_verification_details'
import { enterpriseDetails } from './validators/enterprise_validation_details'
import { identityQuestionsDetails } from './validators/identity_questions_details'
import { phoneDetails } from './validators/phone_verification_details'
import { voiceRecognitionDetails } from './validators/voice_recognition_details'
import { documentValidationDetails } from './validators/document_validation_details'
import { faceRecognitionDetails } from './validators/face_recognition_details'
import { faceSearchDetails } from './validators/face_search_details'
import { electronicSignatureDetails } from './validators/electronic_signature_details'

export const getDetailsValidatorCards = (validation: Validation): Card[] => {
  return DETAILS_CARD_GENERATOR[validation.type](validation)
}

const DETAILS_CARD_GENERATOR: { [key in ValidationTypes]: (validation: Validation) => Card[] } = {
  [ValidationTypes.EmailVerification]: emailDetails,
  [ValidationTypes.PhoneVerification]: phoneDetails,
  [ValidationTypes.EnterpriseData]: enterpriseDetails,
  [ValidationTypes.IdentityQuestions]: identityQuestionsDetails,
  [ValidationTypes.FaceRecognition]: faceRecognitionDetails,
  [ValidationTypes.VoiceRecognition]: voiceRecognitionDetails,
  [ValidationTypes.DocumentValidation]: documentValidationDetails,
  [ValidationTypes.FaceSearch]: faceSearchDetails,
  [ValidationTypes.ElectronicSignature]: electronicSignatureDetails,
}

export const DETAILS_ICONS: { [key in ValidationStatus]: string } = {
  [ValidationStatus.Success]: 'mdi-checkbox-marked-circle',
  [ValidationStatus.Failure]: 'mdi-close-circle',
  [ValidationStatus.Pending]: 'mdi-alert-circle'
}

export const DETAILS_ICONS_COLOR: { [key in ValidationStatus]: string } = {
  [ValidationStatus.Success]: 'green',
  [ValidationStatus.Failure]: 'red',
  [ValidationStatus.Pending]: 'yellow'
}

export const parseDate = (date: Date, completeDate: boolean): string => {
  if (typeof date === 'undefined') {
    return ''
  }

  let selectedDate = date
  if (typeof date === 'string') {
    selectedDate = new Date(date)
  }

  if (!completeDate) {
    return selectedDate.getUTCFullYear().toString()
  }

  return selectedDate.toLocaleDateString(undefined, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    timeZone: 'UTC'
  }).toUpperCase()
}

export const DOC_TYPE: { [key in string]: string } = {
  'national-id': 'DOCUMENTO DE IDENTIDAD NACIONAL',
  passport: 'PASAPORTE',
  'foreign-id': 'DOCUMENTO DE EXTRANJERÍA',
  'driver-license': 'LICENCIA DE CONDUCIR',
  'general-registration': 'RG',
  "cnh": "CNH",
  "pep": "PEP",
  "invoice": "Factura"
}

export const GENDER: { [key in string]: string } = {
  female: 'FEMENINO',
  male: 'MASCULINO'
}

export const COUNTRY: { [key in string]: string } = {
  CO: 'COLOMBIA',
  MX: 'MÉXICO',
  BR: 'BRAZIL'
}

export const LIVENESS_ACTIONS: { [key in string]: string } = {
  'look-left': 'Gira tu cabeza hacia la izquierda',
  'look-right': 'Gira tu cabeza hacia la derecha',
  'look-up': 'Gira tu cabeza hacia arriba',
  'look-down': 'Gira tu cabeza hacia abajo',
  'open-mouth': 'Abre tu boca'
}
