import { ItemType, Card, LayoutItem } from '../'
import { Validation } from '../../validation'
import { DETAILS_ICONS, DETAILS_ICONS_COLOR } from '../details_data'
import jp from 'jsonpath'

export const phoneDetails = (validation: Validation): Card[] => {
  const cards = [
    {
      title: 'Detalles de la validación',
      first_section: [
        {
          type: ItemType.ROW,
          label: 'Número de teléfono',
          value: jp.value(validation, '$.validation_inputs.phone_number'),
          icon: DETAILS_ICONS[validation.validation_status],
          icon_color: DETAILS_ICONS_COLOR[validation.validation_status]
        }
      ] as LayoutItem[]
    }
  ]

  return cards
}
