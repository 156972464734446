import { ValidationTypes, ValidationStatus, FailureStatus } from '../models/validation'

export const VALIDATIONS_NAMES:{[key in ValidationTypes]: string | undefined } = {
  [ValidationTypes.PhoneVerification]: 'Validación de teléfono',
  [ValidationTypes.EmailVerification]: 'Validación de correo',
  [ValidationTypes.DocumentValidation]: 'Validación de documento',
  [ValidationTypes.FaceRecognition]: 'Reconocimiento facial',
  [ValidationTypes.VoiceRecognition]: 'Reconocimiento de voz',
  [ValidationTypes.IdentityQuestions]: 'Preguntas de seguridad',
  [ValidationTypes.EnterpriseData]: 'Validación de datos corporativos',
  [ValidationTypes.FaceSearch]: "Truface",
  [ValidationTypes.ElectronicSignature]: "Firma Electrónica"
}

export const VALIDATIONS_STATUS:{[key in ValidationStatus]: string | undefined } = {
  [ValidationStatus.Success]: 'Exitoso',
  [ValidationStatus.Pending]: 'Pendiente',
  [ValidationStatus.Failure]: 'Fallido'
}

export const VALIDATIONS_FAILURE_STATUS:{[key in FailureStatus]: string | undefined } = {
  [FailureStatus.Canceled]: 'Cancelado',
  [FailureStatus.Declined]: 'Rechazado',
  [FailureStatus.Expired]: 'Expirado',
  [FailureStatus.SystemError]: 'Error de Sistema'
}

export const VALIDATOR_ITEMS = [
  {
    text: VALIDATIONS_NAMES[ValidationTypes.PhoneVerification],
    value: ValidationTypes.PhoneVerification,
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.EmailVerification],
    value: ValidationTypes.EmailVerification,
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.DocumentValidation],
    value: ValidationTypes.DocumentValidation,
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.FaceRecognition],
    value: ValidationTypes.FaceRecognition,
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.VoiceRecognition],
    value: ValidationTypes.VoiceRecognition,
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.IdentityQuestions],
    value: ValidationTypes.IdentityQuestions,
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.EnterpriseData],
    value: ValidationTypes.EnterpriseData,
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.FaceSearch],
    value: ValidationTypes.FaceSearch,
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.ElectronicSignature],
    value: ValidationTypes.ElectronicSignature,
  },
];

export const STATUS_ITEMS = [
  {
    text: VALIDATIONS_STATUS[ValidationStatus.Success],
    value: ValidationStatus.Success,
  },
  {
    text: VALIDATIONS_STATUS[ValidationStatus.Pending],
    value: ValidationStatus.Pending,
  },
  {
    text: VALIDATIONS_STATUS[ValidationStatus.Failure],
    value: ValidationStatus.Failure,
  },
];
