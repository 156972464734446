import { takeLatest, call, put, StrictEffect, delay } from 'redux-saga/effects'
import { AxiosRequestConfig } from 'axios'
import { AuthRequest } from '@/services/truora_api'
import { DeclineReasonsError, InvalidStatusCodeError, TimeMetricsError } from './errors'
import log from '@/services/logger'
import { BASE_API } from '@/config/constants'
import { ValidationsDeclinedReasonMetrics } from '@/store/modules/metrics/state'
import { ValidationMetrics, ValidationMetricsStatus } from '@/models/validation_metrics'

export interface ActionPayload<T> {
  type: string;
  payload: T;
}

export interface GetValidationTimesRequest {
  year: string;
  month: string;
}

export interface GetValidationDeclinedReasonsRequest {
  months: number;
}

interface MetricsData {
  error?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* getMetricsData(retries: number, url: string, params?: AxiosRequestConfig['params']): Generator<StrictEffect, MetricsData, any> {
  const delayMs = 2000

  try {
    for (let i = 0; i < retries; i++) {
      let response = yield call(AuthRequest.get, url, {
        baseURL: BASE_API,
        params
      })

      if (response.status !== 200) {
        throw new InvalidStatusCodeError(response.status)
      }

      const metrics = response.data as ValidationMetrics
      if (metrics.status === ValidationMetricsStatus.IN_PROGRESS) {
        // Handle retry logic
        yield delay(delayMs * (i + 1))
        log.warn('delayed', retries)

        continue
      }

      response = yield call(AuthRequest.get, metrics.result)

      if (response.status !== 200) {
        throw new InvalidStatusCodeError(response.status)
      }

      return { data: response.data }
    }
  } catch (e: any) {
    return { error: e }
  }

  return {}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* handleGetValidationTimes({ payload }: ActionPayload<GetValidationTimesRequest>): Generator<StrictEffect, void, any> {
  const retries = 5

  const data = {
    month: payload.month,
    year: payload.year
  }

  yield put({ type: 'metrics/setValidationTimesLoading', payload: true })

  const validationTimes = yield call(getMetricsData, retries, '/v1/metrics/times', data)
  if (validationTimes.error) {
    const errorResponse = getErrorResponse(validationTimes.error)
    log.error('getting_validation_times_failed: ', errorResponse)

    yield put({ type: 'metrics/setError', payload: new TimeMetricsError() })
    yield put({ type: 'metrics/setValidationTimesLoading', payload: false })

    return
  }

  yield put({ type: 'metrics/setValidationTimes', payload: validationTimes.data })
  yield put({ type: 'metrics/setValidationTimesLoading', payload: false })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* handleGetValidationDeclinedReasons({ payload }: ActionPayload<GetValidationDeclinedReasonsRequest>): Generator<StrictEffect, void, any> {
  let date = new Date()
  const months = payload.months

  const declinedReasonsMetrics: ValidationsDeclinedReasonMetrics[] = []
  yield put({ type: 'metrics/setValidationDeclinedReasonsLoading', payload: true })

  for (let i = 0; i < months; i++) {
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    const retries = 5

    const metrics = yield call(getMetricsData, retries, '/v1/metrics/reasons', {
      year,
      month
    })

    const err = metrics.error
    if (err) {
      const errorResponse = getErrorResponse(err)
      log.error('get declined reasons metrics error: ', errorResponse)

      if (err.response.status !== 404) {
        yield put({ type: 'metrics/setError', payload: new DeclineReasonsError() })
      }

      yield put({ type: 'metrics/setValidationDeclinedReasonsMetrics', payload: declinedReasonsMetrics })
      yield put({ type: 'metrics/setValidationDeclinedReasonsLoading', payload: false })
      return
    }

    declinedReasonsMetrics.push({
      period: `${year}-${month}`,
      metrics: metrics.data
    })

    date = new Date(date.getFullYear(), date.getMonth() - 1, 1)
  }

  yield put({ type: 'metrics/setValidationDeclinedReasonsMetrics', payload: declinedReasonsMetrics })
  yield put({ type: 'metrics/setValidationDeclinedReasonsLoading', payload: false })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getErrorResponse(e: any) {
  return e.response && e.response.data ? e.response.data : e
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* metrics() {
  yield takeLatest('metrics/getValidationTimes', handleGetValidationTimes)
  yield takeLatest('metrics/getValidationDeclinedReasons', handleGetValidationDeclinedReasons)
}
