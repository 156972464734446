import pino from 'pino'

const logger = pino({
  browser: {
    asObject: true,
    write: (o) => {
      // eslint-disable-next-line no-console
      console.log(o)
    }
  }
})

export default logger
