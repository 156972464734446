import { Validation } from '@/models/validation'
import { ValidationState, Validations } from './state'

export const setCurrentValidation = function (state: ValidationState, validation: Validation | null): void {
  state.currentValidation = validation
}

export const setCurrentValidationLoading = function (state: ValidationState, validationLoading: boolean): void {
  state.currentValidationLoading = validationLoading
}

export const setError = function (state: ValidationState, error: Error | null): void {
  state.error = error
}

export const setValidationsLoading = function (state: ValidationState, loading: boolean): void {
  state.validationsLoading = loading
}

export const setParams = function (state: ValidationState, params: Record<string, string>): void {
  state.params = params
}

export const resetParams = function (state: ValidationState): void {
  state.params = {}
}

export const setItemID = function (state: ValidationState, itemID: string): void {
  state.itemID = itemID
}

export const resetItemID = function (state: ValidationState): void {
  state.itemID = ''
}

export const updateValidations = function (state: ValidationState, validations: Validations): void {
  state.validations.history = validations.history
  state.validations.self = validations.self
  state.validations.next = validations.next
}

export const updatePageIndex = function (state: ValidationState, newIndex: number): void {
  state.validationsPagination.currentPage = newIndex
}

export const setLoadedPage = function (state: ValidationState, value: number): void {
  state.loadedPage = value
}

export const updatePages = function (state: ValidationState, pages: string[]): void {
  state.validationsPagination.pages = pages
}

export const resetSearch = function (state: ValidationState, queryParams: Record<string, string>): void {
  state.validationsPagination = {
    ...state.validationsPagination,
    pages: [],
    currentPage: 0,
    searchParams: queryParams
  }
}

export const setDownloadInProgress = function (state: ValidationState, value: boolean): void {
  state.downloadInProgress = value;
};

export const setDownloadTimeout = function (state: ValidationState, value: boolean): void {
  state.downloadTimeout = value;
};

export const setDownloadProgress = function (state: ValidationState, value: number): void {
  state.downloadProgress = value;
};

export const setDownloadRemainingSeconds = function (state: ValidationState, value: number): void {
  state.downloadRemainingSeconds = value;
};

export const setValidationFeedbackLoading = function (state: ValidationState, loading: boolean): void {
  state.validationFeedbackLoading = loading
}

export const setValidationFeedbackSuccess = function (state: ValidationState, success: boolean): void {
  state.validationFeedbackSuccess = success
}

export const setValidationFeedbackReportEnrollmentID = function (state: ValidationState, reportEnrollmentID: string): void {
  state.reportEnrollmentID = reportEnrollmentID
}
