import { ItemType, Card, LayoutItem } from "../";
import { Validation, ActionStatus } from "../../validation";
import { DETAILS_ICONS, DETAILS_ICONS_COLOR, LIVENESS_ACTIONS } from "../details_data";
import { DeclinedReason } from "../../declined_reason";
import jp from "jsonpath";

const checkSimilarityDeclineReasonAndModifyCard = (
  actions: string[],
  card: Card,
  validation: Validation
) => {
  const declineReason: string = jp.value(validation, "$..declined_reason") || "";

  if (actions.length > 0 && declineReason === DeclinedReason.SimilarityThresholdNotPassed) {
    card.first_section = card.second_section;
    delete card.second_section;
    delete card.subtitle;
  }
};

const setLivenessActions = (
  actions: string[],
  actionsStatus: string[],
  livenessActions: LayoutItem[]
) => {
  if (actionsStatus.length === 0 && actions.length > 0) {
    actions.forEach((action: string) => {
      livenessActions.push({
        type: ItemType.ROW,
        label: LIVENESS_ACTIONS[action],
        icon: DETAILS_ICONS[ActionStatus.Pending],
        icon_color: DETAILS_ICONS_COLOR[ActionStatus.Pending],
      });
    });
  }

  actionsStatus.forEach((inputAction: any) => {
    livenessActions.push({
      type: ItemType.ROW,
      label: LIVENESS_ACTIONS[inputAction.action],
      icon: DETAILS_ICONS[inputAction.status],
      icon_color: DETAILS_ICONS_COLOR[inputAction.status],
    });
  });
};

const getVideoPath = (validation: Validation) => {
  const userResponse = jp.value(validation, "$..user_response.input_files") || [];
  let videoPos = 0;

  if (userResponse.length !== 0) {
    videoPos = userResponse.length - 1;
  }

  return `$..user_response.input_files[${videoPos}]`;
};

const checkCardToShow = (
  actions: string[],
  inputIsVideo: boolean,
  cardLiveness: Card,
  cardSelfie: Card
) => {
  if (inputIsVideo || actions.length > 0) {
    return [cardLiveness];
  }

  return [cardSelfie];
};

export const faceRecognitionDetails = (validation: Validation): Card[] => {
  const actions: string[] = jp.value(validation, "$..validation_inputs.liveness_actions") || [];
  const actionsStatus: string[] =
    jp.value(validation, "$..details.face_recognition_validations.actions_status") || [];

  const cardSelfie: Card = {
    title: "Detalles de la validación",
    disclaimer: "Las imágenes contienen filtros para proteger la identidad de los usuarios",
    validation_status: validation.validation_status,

    first_section: [
      {
        type: ItemType.IMAGE,
        label: "Selfie",
        value: jp.value(validation, "$..user_response.input_files[0]"),
      },
    ],
    second_section: [
      {
        type: ItemType.IMAGE,
        label: "Foto",
        value: jp.value(validation, "$..validation_inputs.validation_input_files[0]"),
      },
    ],
  };

  const livenessActions = [] as LayoutItem[];

  setLivenessActions(actions, actionsStatus, livenessActions);

  const videoPath = getVideoPath(validation);
  const inputIsVideo = (jp.value(validation, videoPath) || "").includes("create-validation");

  const cardLiveness: Card = {
    title: "Detalles de la validación",
    subtitle: "Instrucciones de prueba de vida",
    disclaimer: "Las imágenes contienen filtros para proteger la identidad de los usuarios",
    validation_status: validation.validation_status,
    first_section: livenessActions,
    second_section: [
      {
        type: inputIsVideo ? ItemType.VIDEO : ItemType.IMAGE,
        label: "Video",
        value: jp.value(validation, inputIsVideo ? videoPath : "$..user_response.input_files"),
      },
      {
        type: ItemType.IMAGE,
        label: "Foto",
        value: jp.value(validation, "$..validation_inputs.validation_input_files[0]"),
      },
    ],
  };

  checkSimilarityDeclineReasonAndModifyCard(actions, cardLiveness, validation);

  return checkCardToShow(actions, inputIsVideo, cardLiveness, cardSelfie);
};
