
/* eslint-disable */
import Vue from "vue";
import { ACCOUNTS_BASE, getTenant, IS_NEW_ACCOUNT_MODEL_ENABLED } from "@/config/constants";
import { MENU } from "@/config/menu";
import Sidebar from "@/layouts/Sidebar/Sidebar.vue";
import ToolbarMobile from "@/layouts/ToolbarMobile.vue";
import { mapState, mapGetters } from "vuex";

export default Vue.extend({
  name: "DashboardLayout",
  components: {
    Sidebar,
    ToolbarMobile,
  },
  data() {
    return {
      drawer: false,
      mini: false,
    };
  },
  computed: {
    ...mapState("auth", ["account", "tenant"]),
    ...mapGetters<"getUsername">("auth", ["getUsername"]),
    menu() {
      return MENU;
    },
    logoUrl() {
      if (!IS_NEW_ACCOUNT_MODEL_ENABLED) {
        return ACCOUNTS_BASE + "/" + this.account + "#";
      }

      return ACCOUNTS_BASE + "/#";
    },
    userItem() {
      const item = {
        email: this.getUsername,
        accountName: "",
        children: [
          {
            title: "Cerrar sesión",
            path: "/logout",
          },
        ],
      };

      if (IS_NEW_ACCOUNT_MODEL_ENABLED) {
        item.accountName = this.tenant?.company_name || this.tenant?.tenant_id || "";

        return item;
      }

      item.accountName = getTenant();

      return item;
    },
  },
  methods: {
    setDrawer(value) {
      this.drawer = value;
    },
  },
});
