export const BR = 'BR'
export const CL = 'CL'
export const CO = 'CO'
export const CR = 'CR'
export const EC = 'EC'
export const SV = 'SV'
export const MX = 'MX'
export const PE = 'PE'
export const DO = 'DO'
export const VE = 'VE'
export const ALL = 'ALL'

export const COUNTRIES_SHORT = [
  BR, CL, CO, CR, EC, SV, MX, PE, DO, VE, ALL
]

export const COUNTRIES = {
  [CO]: { name: 'Colombia', image: '/img/flags/co.png' },
  [BR]: { name: 'Brasil', image: '/img/flags/br.png' },
  [CL]: { name: 'Chile', image: '/img/flags/cl.png' },
  [CR]: { name: 'Costa Rica', image: '/img/flags/cr.png' },
  [EC]: { name: 'Ecuador', image: '/img/flags/ec.png' },
  [MX]: { name: 'México', image: '/img/flags/mx.png' },
  [PE]: { name: 'Perú', image: '/img/flags/pe.png' },
  [VE]: { name: 'Venezuela', image: '/img/flags/ve.png' },
  [ALL]: { name: 'Internacional' }
}

export type Country = {
  name: string
  code: string
  image: string
}

export const getCountries = (): Country[] => {
  const countries: Country[] = []

  for (const country in COUNTRIES) {
    const ctr = {
      code: country,
      name: COUNTRIES[country].name,
      image: COUNTRIES[country].image
    }
    countries.push(ctr)
  }

  return countries
}
