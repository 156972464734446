
import  Vue from 'vue'
import ValidationCard from './ValidationCard.vue'
import { Card } from '../../models/details'

export default Vue.extend({
  components: {
    ValidationCard
  },
  props: {
    layoutSpecification: {
      required: true,
      type: Array as () => Card[],
    },
  },
})
