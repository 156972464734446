import { ValidationTypes } from '@/models/validation'

export enum FEEDBACK_REASON {
  OtherReason = 'Other',
  LowConfidence = 'Low confidence',
  UsedManyTimes = 'Used many times',
  FaceDoesntMatch = 'Don’t match the face',
  WrongMovements = 'Wrong Movements',
  DigitalEdition = 'Digital edition',
  PreRecordedVideo = 'Pre-recorded video',
  ManipulatedDocumentNumber = 'Manipulated document number',
  ManipulatedPhoto = 'Manipulated photo',
  Fraudster = 'Fraudster',
  DocumentNoAllowed = 'Document no allowed',
  PhotocopyBlackAndWhite = 'Photocopy black and white',
  ColorPhotocopy = 'Color photocopy',
  DigitalPhoto = 'Digital photo',
  OCRProblems = 'OCR problems',
  IncorrectPhrase = 'Incorrect phrase'
}

export enum FACESEARCH_FEEDBACK_REASON {
  ManipulatedDocument = 'manipulated_document',
  ManipulatedPhoto = 'manipulated_photo',
  NonPayment = 'non_payment',
  CriminalRecordsInCompany = 'criminal_records_in_company',
  Fraudster = 'fraudster',
  UserRegister = 'user_register'
}

// TODO: Refactor this once we implement full multi language support
const mapReasonToSpanish: { [key in FEEDBACK_REASON]: string } = {
  [FEEDBACK_REASON.OtherReason]: 'Otro',
  [FEEDBACK_REASON.LowConfidence]: 'Poca Confianza',
  [FEEDBACK_REASON.UsedManyTimes]: 'Usado muchas veces',
  [FEEDBACK_REASON.FaceDoesntMatch]: 'Caras no coinciden',
  [FEEDBACK_REASON.WrongMovements]: 'Movimientos incorrectos',
  [FEEDBACK_REASON.DigitalEdition]: 'Edición digital',
  [FEEDBACK_REASON.PreRecordedVideo]: 'Video pre grabado',
  [FEEDBACK_REASON.ManipulatedDocumentNumber]: 'Manipulación de número de documento',
  [FEEDBACK_REASON.ManipulatedPhoto]: 'Foto manipulada',
  [FEEDBACK_REASON.Fraudster]: 'Estafador',
  [FEEDBACK_REASON.DocumentNoAllowed]: 'Documento no permitido',
  [FEEDBACK_REASON.PhotocopyBlackAndWhite]: 'Fotocopia en blanco y negro',
  [FEEDBACK_REASON.ColorPhotocopy]: 'Fotocopia a color',
  [FEEDBACK_REASON.DigitalPhoto]: 'Foto digital',
  [FEEDBACK_REASON.OCRProblems]: 'Problemas de OCR',
  [FEEDBACK_REASON.IncorrectPhrase]: 'Frase incorrecta',
}

const mapFaceSearchReasonToSpanish: { [key in FACESEARCH_FEEDBACK_REASON]: string } = {
  [FACESEARCH_FEEDBACK_REASON.ManipulatedDocument]: 'Documento manipulado',
  [FACESEARCH_FEEDBACK_REASON.ManipulatedPhoto]: 'Foto manipulada',
  [FACESEARCH_FEEDBACK_REASON.NonPayment]: 'No Pago',
  [FACESEARCH_FEEDBACK_REASON.CriminalRecordsInCompany]: 'Antecedentes penales',
  [FACESEARCH_FEEDBACK_REASON.Fraudster]: 'Estafador',
  [FACESEARCH_FEEDBACK_REASON.UserRegister]: 'Registro de usuario',
}

export type Language = {
  en: string
  es: string
}

export const VAlIDATOR_FEEDBACK_FEEDBACK_REASONS: { [key in ValidationTypes]: Language[] } = {
  [ValidationTypes.PhoneVerification]: [
    {
      en: FEEDBACK_REASON.OtherReason,
      es: mapReasonToSpanish[FEEDBACK_REASON.OtherReason]
    },
    {
      en: FEEDBACK_REASON.LowConfidence,
      es: mapReasonToSpanish[FEEDBACK_REASON.LowConfidence]
    },
    {
      en: FEEDBACK_REASON.UsedManyTimes,
      es: mapReasonToSpanish[FEEDBACK_REASON.UsedManyTimes]
    }
  ],
  [ValidationTypes.EmailVerification]: [
    {
      en: FEEDBACK_REASON.OtherReason,
      es: mapReasonToSpanish[FEEDBACK_REASON.OtherReason]
    },
    {
      en: FEEDBACK_REASON.LowConfidence,
      es: mapReasonToSpanish[FEEDBACK_REASON.LowConfidence]
    },
    {
      en: FEEDBACK_REASON.UsedManyTimes,
      es: mapReasonToSpanish[FEEDBACK_REASON.UsedManyTimes]
    }
  ],
  [ValidationTypes.DocumentValidation]: [
    {
      en: FEEDBACK_REASON.OtherReason,
      es: mapReasonToSpanish[FEEDBACK_REASON.OtherReason]
    },
    {
      en: FEEDBACK_REASON.ManipulatedDocumentNumber,
      es: mapReasonToSpanish[FEEDBACK_REASON.ManipulatedDocumentNumber]
    },
    {
      en: FEEDBACK_REASON.ManipulatedPhoto,
      es: mapReasonToSpanish[FEEDBACK_REASON.ManipulatedPhoto]
    },
    {
      en: FEEDBACK_REASON.Fraudster,
      es: mapReasonToSpanish[FEEDBACK_REASON.Fraudster]
    },
    {
      en: FEEDBACK_REASON.DocumentNoAllowed,
      es: mapReasonToSpanish[FEEDBACK_REASON.DocumentNoAllowed]
    },
    {
      en: FEEDBACK_REASON.PhotocopyBlackAndWhite,
      es: mapReasonToSpanish[FEEDBACK_REASON.PhotocopyBlackAndWhite]
    },
    {
      en: FEEDBACK_REASON.ColorPhotocopy,
      es: mapReasonToSpanish[FEEDBACK_REASON.ColorPhotocopy]
    },
    {
      en: FEEDBACK_REASON.DigitalPhoto,
      es: mapReasonToSpanish[FEEDBACK_REASON.DigitalPhoto]
    },
    {
      en: FEEDBACK_REASON.OCRProblems,
      es: mapReasonToSpanish[FEEDBACK_REASON.OCRProblems]
    }
  ],
  [ValidationTypes.FaceRecognition]: [
    {
      en: FACESEARCH_FEEDBACK_REASON.ManipulatedDocument,
      es: mapFaceSearchReasonToSpanish[FACESEARCH_FEEDBACK_REASON.ManipulatedDocument]
    },
    {
      en: FACESEARCH_FEEDBACK_REASON.ManipulatedPhoto,
      es: mapFaceSearchReasonToSpanish[FACESEARCH_FEEDBACK_REASON.ManipulatedPhoto]
    },
    {
      en: FACESEARCH_FEEDBACK_REASON.NonPayment,
      es: mapFaceSearchReasonToSpanish[FACESEARCH_FEEDBACK_REASON.NonPayment]
    },
    {
      en: FACESEARCH_FEEDBACK_REASON.CriminalRecordsInCompany,
      es: mapFaceSearchReasonToSpanish[FACESEARCH_FEEDBACK_REASON.CriminalRecordsInCompany]
    },
    {
      en: FACESEARCH_FEEDBACK_REASON.Fraudster,
      es: mapFaceSearchReasonToSpanish[FACESEARCH_FEEDBACK_REASON.Fraudster]
    },
    {
      en: FACESEARCH_FEEDBACK_REASON.UserRegister,
      es: mapFaceSearchReasonToSpanish[FACESEARCH_FEEDBACK_REASON.UserRegister]
    }
  ],
  [ValidationTypes.VoiceRecognition]: [
    {
      en: FEEDBACK_REASON.OtherReason,
      es: mapReasonToSpanish[FEEDBACK_REASON.OtherReason]
    },
    {
      en: FEEDBACK_REASON.IncorrectPhrase,
      es: mapReasonToSpanish[FEEDBACK_REASON.IncorrectPhrase]
    }
  ],
  [ValidationTypes.IdentityQuestions]: [
    {
      en: FEEDBACK_REASON.OtherReason,
      es: mapReasonToSpanish[FEEDBACK_REASON.OtherReason]
    }
  ],
  [ValidationTypes.EnterpriseData]: [
    {
      en: FEEDBACK_REASON.OtherReason,
      es: mapReasonToSpanish[FEEDBACK_REASON.OtherReason]
    }
  ],
  [ValidationTypes.FaceSearch]: [
    {
      en: FEEDBACK_REASON.OtherReason,
      es: mapReasonToSpanish[FEEDBACK_REASON.OtherReason]
    }
  ],
  [ValidationTypes.ElectronicSignature]: [
    {
      en: FEEDBACK_REASON.OtherReason,
      es: mapReasonToSpanish[FEEDBACK_REASON.OtherReason]
    }
  ]
}
