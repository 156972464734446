
import Vue from "vue"
import { LayoutItem } from '../../models/details'

export default Vue.extend({
  props: {
    layoutItem: {
      required: true,
      type: Object as () => LayoutItem,
    },
  },
  data() {
    return {
      isPlaying: false,
      pauseIcon: 'mdi-pause',
      playIcon: 'mdi-play',
    }
  },
  methods: {
    setIsPlaying (isPlaying:boolean) {
      this.isPlaying = isPlaying
    },
    async manageAudio () {
      const audio = this.$refs.audio as HTMLAudioElement

      try {
        if (this.isPlaying) {
          await audio.pause()
          this.setIsPlaying(false)
          return
        }
        await audio.play()
        this.setIsPlaying(true)
      } catch (e) {
        this.handleError(e)
      }
    },
    handleError (e) {
      this.$emit('componentError', e)
    },
  }
})
