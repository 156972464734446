import { ValidationTypes } from '@/models/validation'
import { ValidationsTimes, MetricsState, ValidationsDeclinedReasonMetrics } from './state'

export const setValidationTimes = function (state: MetricsState, validationTimes: ValidationsTimes | null): void {
  state.validationsTimes = validationTimes
}
export const setValidationTimesLoading = function (state: MetricsState, loading: boolean): void {
  state.validationTimesLoading = loading
}
export const setValidationDeclinedReasonsMetrics = function (state: MetricsState, validationsDeclinedReasonsHistory: ValidationsDeclinedReasonMetrics[]): void {
  state.validationDeclinedReasonsHistory = validationsDeclinedReasonsHistory
}
export const setValidationDeclinedReasonsLoading = function (state: MetricsState, loading: boolean): void {
  state.validationDeclinedReasonsLoading = loading
}
export const setError = function (state: MetricsState, error: Error | null): void {
  state.error = error
}

export const setValidator = function (state: MetricsState, validator: ValidationTypes): void {
  state.validator = validator
}
