
import Vue from "vue"
import Dropdown, { DropdownItem } from '@bit/truora.pneuma.dropdown'
import Snackbar from '@bit/truora.pneuma.snackbar'
import ValidationTable from '@/components/Metrics/ValidationTable.vue'
import MetricsRefresh from '@/components/Metrics/MetricsRefresh.vue'
import ValidationsBarCharts from '@/components/Metrics/ValidationsBarCharts.vue'
import { mapGetters, mapMutations, mapActions, mapState } from "vuex"
import { VALIDATIONS_NAMES } from '../config/validation'
import { ValidationTypes } from '../models/validation'
import PercentileTable from '@bit/truora.pneuma.percentile-table'

const monthNames = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

const validatorItems = [
  {
    text: VALIDATIONS_NAMES[ValidationTypes.PhoneVerification],
    value: ValidationTypes.PhoneVerification
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.EmailVerification],
    value: ValidationTypes.EmailVerification
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.DocumentValidation],
    value: ValidationTypes.DocumentValidation
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.FaceRecognition],
    value: ValidationTypes.FaceRecognition
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.VoiceRecognition],
    value: ValidationTypes.VoiceRecognition
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.IdentityQuestions],
    value: ValidationTypes.IdentityQuestions
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.EnterpriseData],
    value: ValidationTypes.EnterpriseData
  },
  {
    text: VALIDATIONS_NAMES[ValidationTypes.FaceSearch],
    value: ValidationTypes.FaceSearch
  }
]

export default Vue.extend({
  components: {
    ValidationTable,
    MetricsRefresh,
    ValidationsBarCharts,
    Dropdown,
    PercentileTable,
    Snackbar
  },
  data() {
    return {
      tableMonth : '',
      tableTitle : 'Validaciones',
      tableFailedTitle : 'Detalles fallidas',
      headers : ['Creadas', 'Exitosas', 'Fallidas'],
      failedHeaders : ['Rechazadas', 'Error', 'Expiradas'],
      failedTableItems : {},
      refreshItems : ['Mes actual', 'Mes anterior', 'Hace dos meses'],
      percentileHeaders : ['P25', 'P50', 'P75', 'P95', 'P99'],
      validatorItems,
      selectedItem : validatorItems[0].value,
      notification: {
        show: false,
        title: '',
        message: '',
        type: ''
      }
    }
  },
  methods: {
    ...mapActions("counters", ["fetchCountersHistory"]),
    ...mapActions("metrics", ["getValidationTimes", "getValidationDeclinedReasons"]),
    ...mapMutations("counters", ["reset", "setSelectedValidator"]),
    ...mapMutations("metrics", ["setValidator"]),
    setPreviousMonth (numberMonths:number) : void{
      const currentDate = new Date()
      const date = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - numberMonths
      )

      this.tableMonth = monthNames[date.getMonth()]
    },
    buildTableItems (): void {
      const date = new Date()
      this.tableMonth = monthNames[date.getMonth()]
    },
    handleSelectedItem (item: DropdownItem): void {
      this.setValidator(item)
      this.setSelectedValidator(item)
      this.selectedItem = item
    },
    getMetrics (): void {
      const date = new Date()
      this.tableMonth = monthNames[date.getMonth()]
      this.getValidationTimes({
        year: date.getFullYear().toString(),
        month: (date.getMonth() + 1).toString()
      })
    }
  },
  computed: {
    ...mapGetters("counters", ["getSelectedValidator", "getTotalCountersPerValidator", "getTotalFailedCountersPerValidator"]),
    ...mapGetters("metrics", ["getTableItemPercentiles"]),
    ...mapState("metrics", ["error"]),
    ...mapState("counters", ["state"]),
  },
  destroyed (): void {
    this.reset(this.state)
  },
  mounted () {
    this.fetchCountersHistory()
    this.getValidationDeclinedReasons({
      months: 4
    })
    this.buildTableItems()
    this.getMetrics()
    this.setValidator(this.selectedItem)
  },
  watch: {
    error(value) {
      if (value) {
        this.notification = {
          show: true,
          title: value.message,
          message: '',
          type: 'error'
        }
      }
    }
  }
})
