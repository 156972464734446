
import Vue from 'vue'
import { mapMutations } from "vuex"
import ReportFraud from '@/components/Validations/ReportFraud.vue'
import Btn from '@bit/truora.pneuma.btn'
import { ItemType, LayoutItem } from '../../models/details'
import ItemRow from '@/components/Validations/ItemRow.vue'
import ItemVoiceNote from '@/components/Validations/ItemVoiceNote.vue'

export default Vue.extend({
  components: {
    ItemRow,
    ItemVoiceNote,
    Btn,
    ReportFraud
  },
  props: {
    layoutItem: {
      required: true,
      type: Object as () => LayoutItem,
    },
  },
  data() {
    return {
      reportFraud: false
    }
  },
  methods: {
    ...mapMutations("validations", ["setError"]),
    isRow () {
      return (
        this.layoutItem.type === ItemType.ROW ||
        this.layoutItem.type === ItemType.MAIN_ROW ||
        this.layoutItem.type === ItemType.IMAGE ||
        this.layoutItem.type === ItemType.VIDEO ||
        this.layoutItem.type === ItemType.PDF ||
        this.layoutItem.type === ItemType.URL
      )
    },
    isFraudButton () {
      return this.layoutItem.type === ItemType.FRAUD_BUTTON
    },
    isVoiceNote () {
      return this.layoutItem.type === ItemType.VOICE_NOTE
    },
    handleError (e) {
      this.setError(e)
    }

  }
})
