import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  ACCOUNTS_BASE_API,
  BASE_API,
  TRUORA_WEB_CLIENT,
  SELECTED_ACCOUNT_COOKIE_NAME,
  MANAGE_USER_TOKEN_ENDPOINT,
} from "@/config/constants";
import { getUserSessionFromCookies } from "@/services/user";
import { urlParamsFromObject } from "@/helpers/utils";
import { isCookiesAuthEnabled } from "@/helpers/cookies";
import { router } from "@/router/index";

const cognitoCookiePrefix = "CognitoIdentityServiceProvider";
export const errSessionExpired = Error(
  "La sesión expiró, cierre la sesión e ingrese sus credenciales para continuar"
);
export const errUserDisabled = Error(
  "Tu acceso ha sido deshabilitado para la cuenta. Por favor contacta a tu administrador."
);

export const makeTruoraAPIFullURL = function (
  baseURL: string | undefined,
  url: string | undefined
): string {
  if (!url) {
    return `${baseURL}`;
  }

  return `${baseURL}${url}`;
};

export interface TruoraRequestConfig extends AxiosRequestConfig {
  path?: string;
}

const oldRequest = async function (config: AxiosRequestConfig): Promise<AxiosResponse> {
  config.baseURL = config.baseURL || BASE_API;
  return axios.request({
    ...config,
    url: config.url || makeTruoraAPIFullURL(config.baseURL, config.url),
  });
};

const request = async function (config: AxiosRequestConfig): Promise<AxiosResponse> {
  config.baseURL = config.baseURL || BASE_API;
  if (isCookiesAuthEnabled()) {
    removeCognitoCookies();
  }

  const res = await axios.request({
    ...config,
    url: config.url || makeTruoraAPIFullURL(config.baseURL, config.url),
  });

  return new Promise<AxiosResponse<any>>((resolve) => {
    resolve(res);
  });
};

const getCookieStorageDomain = function () {
  if (window.location.hostname === "localhost") {
    return "localhost";
  }

  return process.env.VUE_APP_COOKIE_STORAGE_DOMAIN || ".truora.com";
};

const removeCognitoCookies = function () {
  document.cookie.split(";").forEach((cookie) => {
    const [name, _] = cookie.trim().split("=");

    if (name.includes(cognitoCookiePrefix)) {
      document.cookie =
        name + `=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;domain=${getCookieStorageDomain()}`;
    }
  });
};

export const getAccountFromPath = function (): string {
  return window.location.pathname.replace(/^\/+/, "").split("/")[0];
};

export const getAccountFromCookie = function (): string {
  const name = "tenant=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieSplit = decodedCookie.split(";");

  for (let i = 0; i < cookieSplit.length; i++) {
    const cookieValue = cookieSplit[i].trim();
    if (cookieValue.indexOf(name) === 0) {
      return cookieValue.substring(name.length, cookieValue.length);
    }
  }

  return "";
};

export const getAccount = (): string => {
  // TODO: Get account only from cookies when new account model is implemented
  const account = getAccountFromPath() || getAccountFromCookie();

  const domainParts = window.location.host.split(".");
  const subDomain = domainParts[0].split(":")[0];
  if (subDomain === "localhost" && account === "") {
    return "localhost";
  }

  if (account === "account") {
    return "";
  }

  return account;
};

const refreshTokenWithAuthenticationEndpoint = async () => {
  if (process.env.VUE_APP_IS_NEW_ACCOUNT_MODEL_ENABLED) {
    const config: TruoraRequestConfig = {
      method: "post",
      path: MANAGE_USER_TOKEN_ENDPOINT,
      baseURL: ACCOUNTS_BASE_API,
      data: {
        request_type: "refresh_access_token",
      },
      withCredentials: true,
      url: MANAGE_USER_TOKEN_ENDPOINT,
    };

    await AuthRequest.postJson(MANAGE_USER_TOKEN_ENDPOINT, config);
    await refreshAccessTokenNewAccountModel();

    return;
  }

  const config: TruoraRequestConfig = {
    method: "post",
    path: "/v1/account/authenticate",
    baseURL: ACCOUNTS_BASE_API,
    data: {
      tenant: getAccount(),
      challenge: "REFRESH_TOKEN_AUTH",
      cookies: "true",
    },
  };

  await Request.postForm("/v1/account/authenticate", config); // No need to read response, cookies auto set
};

const refreshAccessTokenNewAccountModel = async (): Promise<AxiosRequestConfig<any>> => {
  try {
    const accountID = getSelectedAccountCookie();
    if (accountID === "") {
      throw new Error("empty accountID cannot refresh access token");
    }

    const path = "/v1/accounts/" + encodeURIComponent(accountID) + "/token";

    const config: TruoraRequestConfig = {
      method: "post",
      path: path,
      baseURL: ACCOUNTS_BASE_API,
      withCredentials: true,
      url: path,
    };
    return request(config);
  } catch (e: any) {
    console.error("cannot refresh access token: ", e);

    throw errSessionExpired;
  }
};

const getSelectedAccountCookie = (): string => {
  const selectedCookie = document.cookie.split(";").find((cookie) => {
    const [name] = cookie.trim().split("=");
    return name === SELECTED_ACCOUNT_COOKIE_NAME;
  });

  if (selectedCookie) {
    const [, value] = selectedCookie.trim().split("=");
    return value;
  }

  return "";
};

const getAuthConfig = (config: AxiosRequestConfig) => {
  config.headers = {
    ...config.headers,
    "Truora-Client": TRUORA_WEB_CLIENT,
  };

  if (process.env.VUE_APP_TRUORA_API_KEY != "") {
    config.headers = {
      ...config.headers,
      "Truora-API-Key": process.env.VUE_APP_TRUORA_API_KEY,
    };

    return config;
  }

  return config;
};

const oldAuthRequest = async (config: AxiosRequestConfig): Promise<AxiosResponse<any>> => {
  let truoraAPIKey: string;

  try {
    if (process.env.VUE_APP_TRUORA_API_KEY !== "") {
      truoraAPIKey = process.env.VUE_APP_TRUORA_API_KEY || "";

      config.headers = {
        ...config.headers,
        "Truora-API-Key": truoraAPIKey,
        "Truora-Client": TRUORA_WEB_CLIENT,
      };

      return oldRequest(config);
    }

    // Local env var used when running locally. Avoiding redirection to accounts dash
    const cognitoUserSession = await getUserSessionFromCookies();
    if (cognitoUserSession === null) {
      throw new Error("error empty cognito user session");
    }

    truoraAPIKey = cognitoUserSession.getAccessToken().getJwtToken();

    config.headers = {
      ...config.headers,
      "Truora-API-Key": truoraAPIKey,
      "Truora-Client": TRUORA_WEB_CLIENT,
    };
    return oldRequest(config);
  } catch (e: any) {
    console.error("failed to refresh token with cognito: ", e);

    throw errSessionExpired;
  }
};

const isTokenExpiredErrorResponse = (axiosError: any): boolean => {
  if (
    (axiosError?.response?.data?.http_code == "403" || axiosError?.response?.status == "403") &&
    axiosError?.response?.data?.message?.toLowerCase().includes("the api key has expired")
  ) {
    return true;
  }

  return false;
};

const isUserDisabledErrorResponse = (axiosError: any): boolean => {
  if (
    (axiosError?.response?.data?.http_code == "403" || axiosError?.response?.status == "403") &&
    axiosError?.response?.data?.message
      ?.toLowerCase()
      .includes("user access disabled for the account")
  ) {
    return true;
  }

  return false;
};

const executeAuthRequest = async (config: AxiosRequestConfig) => {
  const truoraConfig = getAuthConfig(config) as TruoraRequestConfig;

  try {
    const response = await request(truoraConfig);

    return new Promise<any>((resolve) => {
      resolve([response, false]);
    });
  } catch (axiosError: any) {
    if (isUserDisabledErrorResponse(axiosError)) {
      await router.push("/logout")

      throw errUserDisabled
    }

    if (!isTokenExpiredErrorResponse(axiosError)) {
      throw axiosError;
    }

    if (!isCookiesAuthEnabled()) {
      // Only requests made with cookies authentication enabled should be retried
      // because when the cookies authentication is disabled, the cognito access token is
      // refreshed before the request is sent.
      throw errSessionExpired;
    }
  }

  try {
    await refreshTokenWithAuthenticationEndpoint();

    return new Promise<any>((resolve) => {
      resolve([null, true]);
    });
  } catch (e) {
    throw errSessionExpired;
  }
};

const authRequest = async (config: AxiosRequestConfig): Promise<AxiosResponse<any>> => {
  if (!isCookiesAuthEnabled()) {
    return oldAuthRequest(config);
  }

  const [response, tokenRefreshed] = await executeAuthRequest(config);
  if (!tokenRefreshed) {
    return response;
  }

  const [retryResponse] = await executeAuthRequest(config);

  return retryResponse;
};

export const AuthRequest = {
  get: function (url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    const params = urlParamsFromObject(config.params).toString();
    config.method = "get";
    config.url = [url, params].filter(Boolean).join("?");
    config.params = {};

    return authRequest(config);
  },

  post: function (url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    config.method = "post";
    config.url = url;

    return authRequest(config);
  },

  postForm: function (url: string, config: AxiosRequestConfig): Promise<AxiosResponse> {
    Object.keys(config.data).forEach((key) => {
      if (config.data[key] === undefined || config.data[key] === null) {
        delete config.data[key];
      }
    });

    config.method = "post";
    config.url = url;
    config.data = urlParamsFromObject(config.data).toString();
    config.headers = { "Content-Type": "application/x-www-form-urlencoded", ...config.headers };

    return authRequest(config);
  },

  putForm: function (url: string, config: AxiosRequestConfig): Promise<AxiosResponse> {
    Object.keys(config.data).forEach((key) => {
      if (config.data[key] === undefined || config.data[key] === null) {
        delete config.data[key];
      }
    });

    config.method = "put";
    config.url = url;
    config.data = urlParamsFromObject(config.data).toString();
    config.headers = { "Content-Type": "application/x-www-form-urlencoded", ...config.headers };

    return authRequest(config);
  },

  deleteForm: function (url: string, config: AxiosRequestConfig): Promise<AxiosResponse> {
    Object.keys(config.data).forEach((key) => {
      if (config.data[key] === undefined || config.data[key] === null) {
        delete config.data[key];
      }
    });

    config.method = "delete";
    config.url = url;
    config.data = urlParamsFromObject(config.data).toString();
    config.headers = { "Content-Type": "application/x-www-form-urlencoded", ...config.headers };

    return authRequest(config);
  },

  postJson: function (url: string, config: AxiosRequestConfig): Promise<AxiosResponse> {
    config.method = "post";
    config.url = url;
    config.data = JSON.stringify(config.data);
    config.headers = { "Content-Type": "application/json" };

    return authRequest(config);
  },

  request: (config: AxiosRequestConfig): Promise<AxiosResponse> => authRequest(config),
};

export const Request = {
  postForm: function (url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    config.method = "post";
    config.url = url;
    config.data = urlParamsFromObject(config.data).toString();
    config.headers = { "Content-Type": "application/x-www-form-urlencoded" };

    return request(config);
  },

  request: function (config: AxiosRequestConfig): Promise<AxiosResponse> {
    return request(config);
  },
};
