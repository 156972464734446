import { ValidationState } from "./state";
import { Validation } from "@/models/validation";

export const getCurrentValidation = (state: ValidationState): Validation | null => {
  return state.currentValidation;
};

export const getCurrentValidationLoading = (state: ValidationState): boolean => {
  return state.currentValidationLoading;
};

export const getValidationsHistory = (state: ValidationState): Validation[] => {
  return state.validations.history;
};

export const getValidationsLoading = (state: ValidationState): boolean => {
  return state.validationsLoading;
};

export const getValidationFeedbackLoading = (state: ValidationState): boolean => {
  return state.validationFeedbackLoading;
};

export const getValidationFeedbackSuccess = (state: ValidationState): boolean => {
  return state.validationFeedbackSuccess;
};

export const getParams = (state: ValidationState): Record<string, string> | undefined => {
  return state.params;
};

export const getItemID = (state: ValidationState): string => {
  return state.itemID;
};
