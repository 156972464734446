export class InvalidStatusCodeError extends Error {
  constructor (statusCode: number) {
    super()
    this.message = 'invalid status code: ' + String(statusCode)
    this.name = 'InvalidStatusCodeError'
  }
}

export class UndefinedResponseError extends Error {
  constructor () {
    super()
    this.message = 'undefined response'
    this.name = 'UndefinedResponseError'
  }
}

export class GetValidationsError extends Error {
  constructor () {
    super()
    this.message = 'No pudimos traer las validaciones, por favor inténtalo de nuevo'
    this.name = 'GetValidationsError'
  }
}

export class DownloadValidationsHistoryError extends Error {
  constructor(message: string) {
    super();
    this.message = "No pudimos descargar el historial de validaciones. " + String(message);
    this.name = "DownloadValidationsHistoryError";
  }
}

export class GetValidationError extends Error {
  constructor () {
    super()
    this.message = 'No pudimos traer la validacion, por favor inténtalo de nuevo'
    this.name = 'GetValidationError'
  }
}

export class NextValidationsPageError extends Error {
  constructor () {
    super()
    this.message = 'No pudimos traer la siguiente página de validaciones, por favor inténtalo de nuevo'
    this.name = 'NextValidationsPageError'
  }
}

export class PreviousValidationsPageError extends Error {
  constructor () {
    super()
    this.message = 'No pudimos traer la anterior página de validaciones, por favor inténtalo de nuevo'
    this.name = 'PreviousValidationsPageError'
  }
}

export class StoreFeedbackError extends Error {
  constructor () {
    super()
    this.message = 'No pudimos guardar tu reporte, por favor inténtalo de nuevo'
    this.name = 'StoreFeedbackError'
  }
}

export class TimeMetricsError extends Error {
  constructor () {
    super()
    this.message = 'No pudimos traer las métricas de tiempos, por favor inténtalo de nuevo'
    this.name = 'TimeMetricsError'
  }
}

export class DeclineReasonsError extends Error {
  constructor () {
    super()
    this.message = 'No pudimos traer las métricas de razones de rechazo, por favor inténtalo de nuevo'
    this.name = 'DeclineReasonsError'
  }
}

export class ValidationsCountersError extends Error {
  constructor () {
    super()
    this.message = 'No pudimos traer los contadores por validador, por favor inténtalo de nuevo'
    this.name = 'ValidationsCountersError'
  }
}
