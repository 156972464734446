
import Vue from "vue"
import AppLogo from "@/layouts/AppLogo.vue"
import UserMenu from "@/layouts/Sidebar/UserMenu.vue"
import { mapActions, mapState } from "vuex"
import { ZAPSIGN_SEND_FROM_TRUORA_URL } from "@/config/constants"

export default Vue.extend({
  name: "SideBar",
  components: {
    AppLogo,
    UserMenu,
  },
  props: {
    logoUrl: {
      type: String,
      default: "https://account.truora.com/",
    },
    sections: {
      type: Array as () => MenuSection[],
      required: true,
    },
    userItem: {
      type: Object as () => UserItem,
      required: true,
    },
  },
  computed: {
    ...mapState('zapsign', ['zapsignTemporalToken', 'zapsignTemporalTokenStatus']),
    ...mapState('auth', ['tenant']),
    isActiveItem() {
      return (path) => {
        return this.$route?.path.includes(path)
      }
    },
  },
  methods: {
    ...mapActions("zapsign", ["getZapsignTemporalToken"]),
    handleDashboardNavigationClick(item: MenuItem) {
      if (!this.tenant.zapsign_account_status || this.tenant.zapsign_account_status !== "finished") {
        window.open(item.path, item.openInNewTab ? "_blank" : "_self")

        return
      }

      if (!item.path.includes('zapsign')) {
        window.open(item.path, item.openInNewTab ? "_blank" : "_self")

        return
      }

      this.getZapsignTemporalToken()
    },
    isExternalLink(item: MenuItem): boolean {
      return item.path.includes('http://') || item.path.includes('https://')
    },
  },
  watch: {
    zapsignTemporalTokenStatus(value) {
      if (value === 'success') {
        const zapsignURL = new URL(ZAPSIGN_SEND_FROM_TRUORA_URL)

        zapsignURL.searchParams.append('ACCESS_TOKEN', this.zapsignTemporalToken)

        window.open(zapsignURL.toString(), "_self")
      }
    }
  }
})
