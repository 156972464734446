import {ItemType} from "@/models/details";
import {GENDER, parseDate} from "@/models/details/details_data";
import {Validation} from "@/models/validation";
import jp from 'jsonpath'

export default function getCOPPTFields(validation: Validation): { type: ItemType, label: string, value: any }[] {
  return [
    {
      type: ItemType.ROW,
      label: 'Número de documento',
      value: jp.value(validation, '$..details.document_details.document_number'),
    },
    {
      type: ItemType.ROW,
      label: "Identificación de extranjero",
      value: jp.value(validation, '$..details.document_details.foreign_id'),
    },
    {
      type: ItemType.ROW,
      label: 'Nombres',
      value: jp.value(validation, '$..details.document_details.name'),
    },
    {
      type: ItemType.ROW,
      label: 'Apellidos',
      value: jp.value(validation, '$..details.document_details.last_name'),
    },
    {
      type: ItemType.ROW,
      label: 'Nacionalidad',
      value: jp.value(validation, '$..details.document_details.nationality'),
    },
    {
      type: ItemType.ROW,
      label: 'Género',
      value: GENDER[jp.value(validation, '$..details.document_details.gender')],
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de nacimiento',
      value: parseDate(jp.value(validation, '$..details.document_details.date_of_birth'), true),
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de expedición',
      value: parseDate(jp.value(validation, '$..details.document_details.issue_date'), true),
    },
    {
      type: ItemType.ROW,
      label: 'Lugar de expedición',
      value: jp.value(validation, '$..details.document_details.expedition_place'),
    },
    {
      type: ItemType.ROW,
      label: 'Fecha de expiración',
      value: parseDate(jp.value(validation, '$..details.document_details.expiration_date'), true),
    },
    {
      type: ItemType.ROW,
      label: 'MRZ',
      value: jp.value(validation, '$..details.document_details.machine_readable'),
    }
  ]
}

