import { ItemType, Card, LayoutItem } from '..'
import { Validation } from '../../validation'
import jp from 'jsonpath'

const documentTypes = {
  'national-id': 'Identificación nacional',
  'foreign-id': 'Identificación de extranjero',
  passport: 'Pasaporte',
  code: 'Código'
}

const getFormattedDate = (date:Date):string => (
  date.toLocaleDateString(undefined, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
)

export const enterpriseDetails = (validation: Validation): Card[] => {
  const items = [
    {
      type: ItemType.ROW,
      label: 'List ID',
      value: jp.value(validation, '$.validation_inputs.list_id')
    },
    {
      type: ItemType.ROW,
      label: 'Tipo de documento',
      value: documentTypes[jp.value(validation, '$.validation_inputs.document_type')]
    },
    {
      type: ItemType.ROW,
      label: 'Número de documento',
      value: jp.value(validation, '$.validation_inputs.document_number')
    },
    {
      type: ItemType.ROW,
      label: 'País',
      value: jp.value(validation, '$.validation_inputs.country')
    }
  ] as LayoutItem[]

  const expDate = new Date(jp.value(validation, '$.validation_inputs.expiration_date'))
  if (expDate) {
    items.push({
      type: ItemType.ROW,
      label: 'Fecha de expiración',
      value: getFormattedDate(expDate)
    })
  }

  const cards = [
    {
      title: 'Detalles de la validación',
      first_section: items
    }
  ]

  return cards
}
