import { CognitoUser, CognitoUserPool, CognitoUserSession } from 'amazon-cognito-identity-js'
import { AWS_CONFIG } from '@/config/auth'
import { router } from '@/router'

type UserPoolFromCookies = {
  cognitoClientID: string
  accessToken: string
  userPoolID: string
  issuedAt: number
}

export function getUser(): CognitoUser | null {
  const USER_POOL_CONFIG = {
    UserPoolId: AWS_CONFIG.Auth.userPoolId,
    ClientId: AWS_CONFIG.Auth.userPoolWebClientId,
    Storage: AWS_CONFIG.Auth.cookieStorage
  }

  const userPool = new CognitoUserPool(USER_POOL_CONFIG)

  return userPool.getCurrentUser()
}

export function getUserSession(): Promise<CognitoUserSession> {
  const NO_USER_ERROR_MESSAGE = 'Could not find the cognito user'

  return new Promise<CognitoUserSession>((resolve, reject) => {
    const cognitoUser = getUser()
    if (cognitoUser) {
      cognitoUser.getSession((err: Error, session: CognitoUserSession | null) => {
        if (!err) {
          resolve(session as CognitoUserSession)
        } else {
          reject(err)
        }
      })
    } else {
      reject(new Error(NO_USER_ERROR_MESSAGE))
    }
  })
}

export function getMostRecentUserPool(): UserPoolFromCookies | null {
  const cookies = document.cookie.split(";")

  const userPools = [] as UserPoolFromCookies[]

  cookies.forEach((cookie) => {
    const parts = cookie.split("=")
    const name = parts.shift()
    const value = parts.join("=")

    if (!name) {
      return
    }

    if (!name.includes("CognitoIdentityServiceProvider")) {
      return
    }

    const nameParts = name.split(".")

    let cognitoClientID = ""

    if (nameParts.length > 1) {
      cognitoClientID = nameParts[1]
    }

    if (!name.includes("accessToken")) {
      return
    }

    const accessToken = value

    const JWTParts = accessToken.split(".")

    if (JWTParts.length !== 3) {
      return
    }

    const jwtPayload = atob(JWTParts[1])

    const parsedPayload = JSON.parse(jwtPayload)

    const issuerParts = parsedPayload.iss.split("/")

    const userPoolID = issuerParts.pop()
    const issuedAt = parsedPayload.iat

    userPools.push({
      cognitoClientID,
      accessToken,
      userPoolID,
      issuedAt,
    })
  })

  if (userPools.length === 0) {
    return null
  }

  const sortedUserPools = userPools.sort((a, b) => b.issuedAt - a.issuedAt)

  const chosenUserPool = sortedUserPools[0] // Choose the user pool with most recent issuedAt

  return chosenUserPool
}

export async function getUserSessionFromCookies(): Promise<CognitoUserSession | null> {
  const userPoolFromCookies = getMostRecentUserPool()

  if (userPoolFromCookies === null) {
    return null
  }

  const userPoolData = {
    UserPoolId: userPoolFromCookies.userPoolID,
    ClientId: userPoolFromCookies.cognitoClientID,
    Storage: AWS_CONFIG.Auth.cookieStorage,
  }

  const userPool = new CognitoUserPool(userPoolData)

  const user = userPool.getCurrentUser()

  if (user === null) {
    return null
  }

  return new Promise((resolve, reject) => {
    user.getSession((err, session) => {
      if (err) {
        return reject(err)
      }

      resolve(session)
    })
  })
}

export function setAccountOnPath(account: string): void {
  if (window.location.pathname === '/' + account) {
    return
  }

  history.replaceState(null, "", account + "#" + router.currentRoute.path)
}
