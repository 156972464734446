import history from '@/assets/icons/history.svg'
import statistics from '@/assets/icons/statistics.svg'

import identityLogo from "@/assets/logos/digital-identity.svg"
import truchecksLogo from "@/assets/logos/truchecks-app.svg"
import engagementLogo from "@/assets/logos/customer-engagement.svg"
import zapsignLogo from "@/assets/logos/zapsign.svg"

export const MENU: MenuSection[] = [
  {
    id: 'validations-section',
    items: [
      {
        icon: history,
        title: "Historial",
        path: "/history",
      },
      {
        icon: statistics,
        title: "Estadísticas",
        path: "/metrics"
      }
    ]
  },
  {
    id: 'explore-section',
    title: 'Explorar',
    items: [
      {
        path: process.env.VUE_APP_CHECKS_BASE || "https://checks.truora.com",
        icon: truchecksLogo,
        title: "TruChecks",
      },
      {
        title: "Digital Identity",
        icon: identityLogo,
        path: process.env.VUE_APP_DI_BASE || "https://flows.truora.com",
      },
      {
        title: "Engagement",
        icon: engagementLogo,
        path: process.env.VUE_APP_ENGAGEMENT_BASE || "https://connect.truora.com",
      },
      {
        title: "Zapsign",
        icon: zapsignLogo,
        path: process.env.VUE_APP_ZAPSIGN_BASE|| "https://app.zapsign.co/acesso/criar-conta",
      }
    ]
  },
]
