
import Vue from "vue"

export default Vue.extend({
  props: {
    userItem: {
      type: Object as () => UserItem,
      required: true,
    },
  },
  data: () => {
    return {
      showMenu: false,
    }
  },
})
