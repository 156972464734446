import { AuthState } from './state'
import { AxiosError } from 'axios'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { isCookiesAuthEnabled } from '@/helpers/cookies'

export const authorization = function (state: AuthState): string | undefined {
  if (state.user === null) {
    return ''
  }

  const session = state.user.getSignInUserSession()
  if (session === null) {
    return ''
  }

  return session.getAccessToken().getJwtToken()
}

export const accountNotFound = function (state: AuthState): boolean {
  if (
    state.error !== null &&
    isAxiosError(state.error) &&
    state.error.response !== undefined
  ) {
    return state.error.response.status === 404
  }

  return false
}

function isAxiosError(error: Error): error is AxiosError {
  return (error as AxiosError).response !== undefined
}

export const getAuthUser = (state: AuthState): CognitoUser | null => {
  return state.user
}

export const getUsername = (state: AuthState): string => {
  if (isCookiesAuthEnabled()) {
    return state.username ? state.username : ''
  }

  if (state.user?.getUsername) {
    return state.user.getUsername() || ''
  }

  return ''
}
