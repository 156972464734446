import { takeLatest, call, put, StrictEffect } from 'redux-saga/effects'
import { AuthRequest } from '@/services/truora_api'
import log from '@/services/logger'
import { ACCOUNTS_BASE_API } from '@/config/constants'
import { InvalidStatusCodeError, ValidationsCountersError } from './errors'
import { ValidationsCounters } from '../store/modules/counters/state'

export interface ActionPayload<T> {
  type: string;
  payload: T;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getErrorResponse(e: any): string {
  return e.response && e.response.data ? e.response.data.toString() : e.toString()
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* handleFetchCountersHistory(): Generator<StrictEffect, void, any> {
  let date = new Date()
  const countersHistory: ValidationsCounters[] = []
  yield put({ type: 'counters/setCountersLoading', payload: true })

  try {
    for (let i = 0; i < 4; i++) {
      const res = yield call(AuthRequest.get, '/v1/counters', {
        baseURL: ACCOUNTS_BASE_API,
        params: {
          product: 'validations',
          year: date.getFullYear(),
          month: date.getMonth() + 1
        }
      })

      if (res.status !== 200) { // TODO: refactor this, it will never enter
        const err = new InvalidStatusCodeError(res.status)
        log.error('getting counters failed:', err)
        yield put({ type: 'counters/setError', payload: new ValidationsCountersError() })

        return
      }

      countersHistory.push(res.data)

      date = new Date(date.getFullYear(), date.getMonth() - 1, 1)
    }
  } catch (e: any) {
    if (e.response.status === 400) {
      return
    }

    const errorResponse = getErrorResponse(e)
    log.error('get counters error: ', errorResponse)
    yield put({ type: 'counters/setError', payload: new ValidationsCountersError() })
  } finally {
    yield put({ type: 'counters/setCountersHistory', payload: countersHistory })
    yield put({ type: 'counters/setCountersLoading', payload: false })
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* counters() {
  yield takeLatest('counters/fetchCountersHistory', handleFetchCountersHistory)
}
