
import { VALIDATIONS_STATUS } from '@/config/validation'
import { ValidationStatus } from '@/models/validation'
import Vue from "vue"

export default Vue.extend({
  props: {
    validationStatus: {
      required: true,
      type: String as () => ValidationStatus,
    },
  },
  data() {
    return {
      statusText: '' as string | undefined
    }
  },
  methods: {
    getStatusClass () {
      switch (this.validationStatus) {
        case ValidationStatus.Success:
          return 'status-text-success'
        case ValidationStatus.Pending:
          return 'status-text-pending'
        case ValidationStatus.Failure:
          return 'status-text-failure'
      }
    },
  },
  beforeMount () {
    this.statusText = VALIDATIONS_STATUS[this.validationStatus]
  },
})
