import { ZapsignState } from "./state";

export const setZapsignTemporalToken = function (
  state: ZapsignState,
  zapsignTemporalToken: string
): void {
  state.zapsignTemporalToken = zapsignTemporalToken;
};

export const setZapsignTemporalTokenStatus = function (
  state: ZapsignState,
  zapsignTemporalTokenStatus: string
): void {
  state.zapsignTemporalTokenStatus = zapsignTemporalTokenStatus;
};

export const setZapsignTemporalTokenError = function (
  state: ZapsignState,
  zapsignTemporalTokenError: object
): void {
  state.zapsignTemporalTokenError = zapsignTemporalTokenError;
};

export const setZapsignTemporalTokenLoading = function (
  state: ZapsignState,
  zapsignTemporalTokenLoading: boolean
): void {
  state.zapsignTemporalTokenLoading = zapsignTemporalTokenLoading;
};
