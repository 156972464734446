
import Vue from "vue";
import { mapActions, mapMutations, mapState } from "vuex";
import { LANGUAGES } from "@/config/constants";
import Datepicker from "@bit/truora.pneuma.datepicker";
import { STATUS_ITEMS, VALIDATOR_ITEMS } from "@/config/validation";

export default Vue.extend({
  components: {
    Datepicker,
  },
  data() {
    return {
      languages: LANGUAGES,
      selectedLanguage: "es",
      selectedStartDate: "",
      selectedEndDate: "",
      selectedValidationType: "",
      selectedValidationStatus: "",
      statusItems: STATUS_ITEMS,
      validatorItems: VALIDATOR_ITEMS,
    };
  },
  props: {
    startDate: {
      required: false,
      type: String,
      default: "",
    },
    endDate: {
      required: false,
      type: String,
      default: "",
    },
    validationStatus: {
      required: false,
      type: String,
      default: "",
    },
    validationType: {
      required: false,
      type: String,
      default: "",
    },
  },
  computed: {
    ...mapState<
      "downloadInProgress" | "downloadTimeout" | "downloadProgress" | "downloadRemainingSeconds"
    >("validations", [
      "downloadInProgress",
      "downloadTimeout",
      "downloadProgress",
      "downloadRemainingSeconds",
    ]),
    canResetValidationType(): boolean {
      return this.selectedValidationType != "";
    },
    canResetValidationStatus(): boolean {
      return this.selectedValidationStatus != "";
    },
  },
  methods: {
    ...mapActions("validations", ["fetchValidationsHistory"]),
    ...mapMutations("validations", ["setDownloadTimeout", "setError"]),

    resetValidationStatus() {
      this.selectedValidationStatus = "";
    },

    resetValidationType() {
      this.selectedValidationType = "";
    },

    humanizedDuration: function () {
      if (!this.downloadRemainingSeconds) {
        return "";
      }
      if (this.downloadRemainingSeconds < 60) {
        return this.downloadRemainingSeconds + " segundos";
      }
      if (this.downloadRemainingSeconds < 3600) {
        return Math.floor(this.downloadRemainingSeconds / 60) + " minutos";
      }
      return Math.floor(this.downloadRemainingSeconds / 3600) + " horas";
    },

    initDownload: function () {
      this.setDownloadTimeout(false);

      if (!this.validate()) {
        return;
      }

      this.fetchValidationsHistory({ ...this.getParams() });
    },
    getParams() {
      const params: Record<string, string> = {};

      params.start_date = this.selectedStartDate;
      params.end_date = this.selectedEndDate;
      params.language = this.selectedLanguage;

      if (this.selectedValidationType !== "") {
        params.validation_type = this.selectedValidationType;
      }

      if (this.selectedValidationStatus !== "") {
        params.validation_status = this.selectedValidationStatus;
      }

      return params;
    },
    setStartDate(startDate: string): void {
      this.selectedStartDate = startDate;
    },
    setEndDate(endDate: string): void {
      this.selectedEndDate = endDate;
    },
    validate: function () {
      if (
        !this.selectedStartDate ||
        !this.selectedEndDate ||
        this.selectedEndDate < this.selectedStartDate
      ) {
        this.setError(new Error("Intervalo de fechas no válido."));

        return false;
      }

      return true;
    },
  },
  watch: {
    startDate(value: string) {
      this.selectedStartDate = value;
    },
    endDate(value: string) {
      this.selectedEndDate = value;
    },
    validationStatus(value: string) {
      this.selectedValidationStatus = value;
    },
    validationType(value: string) {
      this.selectedValidationType = value;
    },
  },
  mounted() {
    this.selectedEndDate = this.endDate;
    this.selectedStartDate = this.startDate;
    this.selectedValidationStatus = this.validationStatus;
    this.selectedValidationType = this.validationType;
    this.setDownloadTimeout(false);
  },
  destroyed() {
    this.setDownloadTimeout(false);
  },
});
